<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
            :busy="isLoading"
            head-variant="light"
            :no-local-sorting="true"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            per-page="0"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :sticky-header="height"
            no-border-collapse
            responsive
            show-empty
            empty-text="Non ci sono record da mostrare"
            small
            @sort-changed="sortingChanged"
            :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35"/>
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                  size="sm"
                  variant="lisaweb"
                  @click="openModal(row.item, row.index, $event.target)"
                  title="Modifica"
                  :disabled="!!row.item.read_only"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:[fattuttoEntityCode]="row">
            <!-- <pre>{{ row.item }}</pre> -->
            {{ renderEntityCode(row.item) }}
            <!-- <template
              v-if="
                Object.prototype.hasOwnProperty.call(
                  row.item.fattutto_transmission,
                  'record'
                ) &&
                ![0, 1, 5, 6].includes(
                  row.item.fattutto_transmission.transmission_status
                )
              "
            >
              {{
                `${getNestedValue(
                  row.item,
                  fattutto_records_sent
                )} / ${getNestedValue(row.item, fattutto_records)}`
              }}
            </template>
            <span v-else>-</span> -->
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
              label-for="per-page-select"
              label-cols-sm="3"
              label-cols-md=""
              label-cols-lg="3"
              label-size="sm"
              md="auto"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 68px"
                size="sm"
                @change="onPageChange(1)"
                :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
              v-model="currentPage"
              limit="3"
              :total-rows="totalItems"
              :per-page="perPage"
              size="sm"
              @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
              @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>

      <b-modal
          :id="infomodalName"
          :dialog-class="customModalClass"
          :title="custoModalTitle"
          size="lg"
          scrollable
          ok-only
          ok-variant="lisaweb"
          header-bg-variant="lisaweb"
          no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                @click="setTab(tab, index)"
                :title="tab.text"
                :active="index === tabIndex"
                title-item-class="lisaweb"
            >
              <button-group-tab></button-group-tab>
              <keep-alive>
                <component
                    v-if="selected === tab.name"
                    :is="selected"
                    :resourceId="modalResourceId"
                    :dbRowData="dbRowData"
                    @fetch="fetch"
                    @updateEnd="updateEnd"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import BaseInputPagination from "@/components/form/BaseInputPagination";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseIcon from "@/components/BaseIcon";
import template from "./template";
import Edit from "@/components/fattutto/configuration/details/Edit";
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "FattuttoConfigurationTableRef",
  extends: template,
  data() {
    return {
      customModalClass: "modal-fattutto-configuration",
      custoModalTitle: "Modifica entità per flusso contabilità fiscale",
      tabs: [{name: "Edit", text: "Edit"}],
      selected: "Edit",
      modalResourceId: null,
      dbRowData: null, // tab Contraente deve avere i dati della polizza per Voltura
      tabIndex: 0,
      fattutto_entity_code: "configurable.code",
    };
  },
  components: {
    ButtonGroupTab,
    BaseIcon,
    BaseInputPagination,
    Edit,
  },
  methods: {
    updateEnd() {
      this.$emit("forcedFetch");
      this.$bvModal.hide("modal");
    },
    // createTrasmission(item) {
    //   this.isLoading = true;
    //   const Repo = RepositoryFactory.get("fattutto");
    //   const payload = {
    //     date: item.consolidated_at,
    //   };
    //   Repo.create_transmission(payload)
    //     .then(() => {
    //       this.$emit("transmissionActionsEnd");
    //       this.$showSnackbar({
    //         preset: "success",
    //         text: `Azione Completata: Trasmissione Creata`,
    //       });
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //     });
    //   // .finally(() => {
    //   //   this.isLoading = false;
    //   // });
    // },
    renderEntityCode(item) {
      let ret = "";
      switch (item.configurable_type) {
        case "App\\Models\\Insurer":
          ret = `${item.configurable.code} - ${item.configurable.title}`;
          break;
        case "App\\Models\\Broker":
          ret = `${item.configurable.attributables.BUID}${
              item.configurable?.attributables?.SURN
                  ? ` - ${item.configurable.attributables.SURN}`
                  : ""
          } ${
              item.configurable?.attributables?.NAME
                  ? ` ${item.configurable.attributables.NAME}`
                  : ""
          }`;
          break;
        case "App\\Models\\VariousAccounting":
          ret = `${item.configurable.code} - ${item.configurable.title}`;
          break;
        case "App\\Models\\Sector":
          ret = `${item.configurable.code} - ${item.configurable.title}`;
          break;
        default:
          console.log("No configurable_type matched!");
          break;
      }
      return ret;
    },
    getNestedValue(obj, path) {
      return path
          .split(".")
          .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
    },
    rowClass(item) {
      if (!item || item?.active == null) return "";
      if (item.active) return "configuration-1";
      return "configuration-2";
    },
  },
  computed: {
    fattuttoEntityCode() {
      return `cell(${this.fattutto_entity_code})`;
    },
  },
  // beforeMount() {
  //   this.actions.push("infomodal");
  // },
};
</script>

<style lang="scss" scoped>
/* ROSSO */
:deep(.configuration-2 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(228, 33, 33, 0.3) 100%
  );
}

/* VERDE */
:deep(.configuration-1 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(19, 172, 19, 0.4) 100%
  );
}

/* NEUTRO */
:deep(.configuration-0 td:nth-child(2)) {
  background: rgb(255, 255, 255);
}
</style>
