<template>
  <div class="mx-1">
    <table-task-inheritance
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noActions
      noInnerWidth
      @forcedFetch="forcedFetch"
    >
    </table-task-inheritance>
  </div>
</template>

<script>
import TableTaskInheritance from "@/components/tables/TasksInheritance";
import FormMixin from "@/mixins/FormMixin";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import { toLocaleDate } from "@/utils/dates";
import { getDictionary } from "@/utils/dictionary";
import { mapActions } from "vuex";

export default {
  mixins: [CalendarEventsAndTasksMixin, FormMixin],
  name: "Notes",
  data() {
    return {
      entity_type: "policy",
      filter: this.initFilter(),
      filterName: "filterInsurancePolicyNoteInheritanceDetail",
      repository: "task",
      resource: "tasks",
      tableRef: "NoteDetailTableRef",
      taskable_id: this.resourceId,
      taskable: "insurance_policy",
      task_type: 2,
      task_id: null,
      formLoaded: false,
      fields: [
        {
          key: "custom_actions",
          label: "Azioni",
          sortable: false,
        },
        {
          key: "insurance_policies[0].number",
          label: this.getDictionary("polizza_sostituita", "task"),
          sortable: false,
        },
        {
          key: "contraente",
          label: this.getDictionary("contractor"),
          sortable: false,
        },
        {
          key: "insurance_policies[0].expired_at",
          label: this.getDictionary("expired_at", "insurance_policy"),
          sortable: false,
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "broker",
          label: this.getDictionary("salesman"),
          sortable: false,
        },
        {
          key: "description",
          label: this.getDictionary("description", "task"),
          sortable: false,
          sortKey: "description",
        },
        {
          key: "insurance_policies_new_policy[0].old_policy.number",
          label: this.getDictionary("polizza_sostituente", "task"),
          formatter: (value) => (value ? value : "-"),
          sortable: false,
        },
        {
          key: "insurance_policies_new_policy[0].old_policy.status_policy",
          label: this.getDictionary("status_policy", "insurance_policy"),
          formatter: (value) => (value ? value.text : "-"),
          sortable: false,
        },
        {
          key: "insurance_policies_new_policy[0].old_policy.covered_at",
          label: this.getDictionary("covered_at", "insurance_policy"),
          sortable: false,
          formatter: (value) => (value ? toLocaleDate(value) : "-"),
        },
      ],
    };
  },
  components: {
    TableTaskInheritance,
  },
  methods: {
    getDictionary,
    initFilter() {
      let init = {
        byAttribute: {
          inheritance: 2,
        },
        byInsurancePolicy: {
          status_policy: 3,
        },
      };
      return init;
    },
    forcedFetch() {
      this.onSearch();
    },
    onSearch(name) {
      // onSearch() {
      let criteria = this.filter[name];
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          // this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      //   removeFilterByName: "removeByName",
    }),
  },
  mounted() {
    // this.onSearch(this.filterName);
    this.onSearch();
  },
};
</script>
