<template>
  <div class="mx-1">
    <b-row v-if="formLoaded">
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="createTask(task_type)"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>
    <table-task
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      @edit="onEdit"
      @destroy="destroyEvent"
      :onlyActions="['edit', 'destroy']"
      noInnerWidth
    >
    </table-task>
    <div v-if="formLoaded">
      <event-create-modal
        v-model="evt"
        :beFormCreate="beCreateForm['task']"
        :beRulesCreate="beRules['task']"
        :entity="entity_type"
        @cancel="cancelEventCreation"
        @create="storeEvent"
        @addAppointment="onAddAppointment"
      ></event-create-modal>
      <event-change-modal
        v-model="evt"
        :beFormUpdate="beEditForm['task']"
        :beRulesUpdate="beRules['task']"
        :entity="entity_type"
        @cancel="cancelEventChange"
        @update="putEvent({ id: task_id })"
      ></event-change-modal>
    </div>
  </div>
</template>

<script>
import TableTask from "@/components/tables/Tasks";
import FormMixin from "@/mixins/FormMixin";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { toLocaleDate } from "@/utils/dates";
import { mapGetters } from "vuex";

export default {
  mixins: [
    CalendarEventsAndTasksMixin,
    FormMixin,
    DetailTabAutofetchTableMixin,
  ],
  name: "Notes",
  data() {
    return {
      entity_type: "policy",
      filter: this.initFilter(),
      filterName: "filterInsurancePolicyNoteDetail",
      repository: "task",
      resource: "tasks",
      tableRef: "NoteDetailTableRef",
      taskable_id: this.resourceId,
      taskable: "insurance_policy",
      task_type: 2,
      task_id: null,
      formLoaded: false,
      beCreateForm: {},
      beEditForm: {},
      form: {
        task: {
          id: null,
          title: null,
          description: null,
          task_type: null,
          status_task: null,
          users: null,
          owner_id: null,
          taskable: null,
          taskable_id: null,
          start: null,
          end: null,
          startTime: null,
          endTime: null,
          expired_at: null,
        },
      },
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "task"),
          sortable: false,
          sortKey: "title",
        },
        {
          key: "description",
          label: this.getDictionary("description", "task"),
          sortable: false,
          sortKey: "description",
        },
        {
          label: this.getDictionary("task_type", "task"),
          key: "task_type.text",
          sortable: false,
          sortKey: "task_type",
        },
        {
          label: this.getDictionary("is_public", "task"),
          key: "is_public.text",
          sortable: false,
          sortKey: "is_public",
        },
        {
          label: this.getDictionary("inheritance", "task"),
          key: "inheritance",
          sortable: false,
          sortKey: "inheritance",
          formatter: (value) => this.getInheritanceLabel(value),
        },
        {
          label: this.getDictionary("users", "task"),
          key: "users",
          sortable: false,
          sortKey: "users",
        },
        {
          label: this.getDictionary("owner", "task"),
          key: "owner_id",
          sortable: false,
          // sortKey: "users",
          formatter: (value) => this.getOwner(value),
        },
        {
          label: this.getDictionary("created_at", "task"),
          key: "created_at",
          sortable: false,
          // sortKey: "users",
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
        },
      ],
    };
  },
  components: {
    TableTask,
  },
  methods: {
    getInheritanceLabel(value) {
      if (!value) return "-";
      let ret;
      switch (value) {
        case 1:
          ret = "Automatica";
          break;
        case 0:
          ret = "Inattiva";
          break;
        case 2:
          ret = "Manuale";
          break;
        default:
          console.log("No entity matched!");
          break;
      }
      return ret;
    },

    getOwner(value) {
      if (!value) return "-";
      return (
        this.getUsers().find((user) => user.value === value).username ?? "-"
      );
    },
    initFilter() {
      let init = {
        byInsurancePolicy: { id: this.resourceId },
        byAttribute: { task_type: 2 },
      };
      return init;
    },
    onEdit(id) {
      this.task_id = id;
      this.handleChangeClick({ id: id });
    },
    ...mapGetters("auth", {
      getUsers: "users",
    }),
  },
  props: {
    resourceId: Number,
  },
  beforeMount() {
    this.initDefaultFormValues({ task_type: this.task_type });
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.beCreateForm[this.repository] = this.beEditForm[this.repository] =
          this.convertDataToCalendarEvents([this.beForm[this.repository]])[0];
        this.formLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
