var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"mode":_vm.vmode,"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, validated, valid, errors }){return [_c('b-form-group',{key:`input-group-${_vm.uid}`,class:{
      asterisk:
        Object.keys(_vm.rules).includes('required') ||
        (Object.keys(_vm.rules).includes('required_if') && !valid) ||
        (Object.keys(_vm.rules).includes('required_with') && !valid),
    },attrs:{"id":`input-group-${_vm.uid}`,"label":`${_vm.label}`,"label-for":`input-${_vm.uid}`},scopedSlots:_vm._u([(
        (_vm.multiple && !_vm.taggable) ||
        (_vm.inputVal && Array.isArray(_vm.inputVal) && _vm.inputVal.length > 5)
      )?{key:"label",fn:function(){return [(_vm.hasSel)?_c('span',{key:`tooltip-${_vm.uid}`,staticStyle:{"cursor":"pointer"},attrs:{"id":`tooltip-${_vm.uid}`},on:{"click":function($event){return _vm.tooltip()}}},[_c('b-icon',{attrs:{"icon":"info-circle"}})],1):_vm._e(),_vm._v("\n      "+_vm._s(_vm.label)+"\n      "),(_vm.hasSel)?_c('b-tooltip',{attrs:{"show":_vm.showTooltip,"target":`tooltip-${_vm.uid}`,"placement":"top"},on:{"update:show":function($event){_vm.showTooltip=$event}}},[_c('div',{staticClass:"custom-grid-wrapper",domProps:{"innerHTML":_vm._s(_vm.tltp)}})]):_vm._e()]},proxy:true}:null],null,true)},[_vm._v(" "),_c('multiselect',{class:_vm.getValidationStateClass({ dirty, validated, valid, errors }),attrs:{"id":_vm.id,"name":`input-${_vm.uid}`,"options":_vm.options.map((option) => option.value),"custom-label":_vm.customLabel,"taggable":_vm.taggable,"multiple":_vm.multiple,"disabled":_vm.disabled,"closeOnSelect":_vm.closeOnSelect,"clearOnSelect":_vm.clearOnSelect,"searchable":true,"loading":_vm.isLoading,"placeholder":_vm.placeholder,"allow-empty":_vm.allowEmpty,"selectLabel":"[Invio]","tag-placeholder":_vm.getDictionary('multiselect_no_results', 'form'),"selectedLabel":_vm.getDictionary('multiselect_selected', 'form'),"deselectLabel":"[Invio]","aria-describedby":`input-${_vm.id}-live-feedback`,"preselect-first":_vm.preselectFirst},on:{"tag":_vm.doNotAddTag,"select":_vm.onSelect,"remove":_vm.onRemove,"input":_vm.onInput,"search-change":_vm.asyncFind},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return (
          (_vm.multiple && !_vm.taggable) ||
          (_vm.inputVal && Array.isArray(_vm.inputVal) && _vm.inputVal.length > 5)
        )?[(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" opzioni selezionate")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.getDictionary("multiselect_no_options", "form")))]),_vm._v(" "),_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.getDictionary("multiselect_no_results", "form")))]),_vm._v(" "),_vm._v(" "),(
          (_vm.multiple && !_vm.taggable) ||
          (_vm.inputVal && Array.isArray(_vm.inputVal) && _vm.inputVal.length > 5)
        )?_c('template',{slot:"tag"},[_vm._v(_vm._s(""))]):_vm._e()],2),_vm._v(" "),(_vm.multiple && _vm.defaultHtmlMultipleSelect)?[_c('div',{staticClass:"mt-4",attrs:{"data-type":"default-html-multiple-select"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"light"},on:{"click":function($event){return _vm.toggleAdvancedSelection()}}},[_vm._v(_vm._s(_vm.advancedSelection
              ? "Nascondi selezione avanzata"
              : "Mostra selezione avanzata"))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.advancedSelection),expression:"advancedSelection"}],staticClass:"my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],staticClass:"default-html-multiple-select",attrs:{"multiple":"","id":`defaultHtmlMultipleSelect_${_vm.id}`},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inputVal=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.options),function(option,index){return _c('option',{key:`${_vm.uid}_${index}`,domProps:{"value":option.value}},[_vm._v("\n            "+_vm._s(option.text)+"\n          ")])}),0)])]:_vm._e(),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"id":`input-${_vm.id}-live-feedback`}},[_vm._v(_vm._s(errors[0]))])],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }