<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
        >
          <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea
        </b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                  name="NOM"
                  type="text"
                  label="Nominativo"
                  v-model="filter.byAttribute.title"
                  placeholder="Inserisci un nome"
              />
            </div>
            <div class="col-md-3">
              <base-input
                  name="BUID"
                  type="text"
                  label="Codice"
                  v-model="filter.byAttribute.BUID"
                  placeholder="Inserisci un codice"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
              >Cerca
              </b-button
              >
              <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
              >Reset
              </b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <salesmen
        @edit="onEdit"
        :fields="fields"
        :repository="repository"
        @rowSelector="commission"
        :resource="resource"
        :filterName="filterName"
        @destroy="onDestroy"
        :filterOn="{
        byRelations: ['byCorrespondence', 'byAddressBook'],
      }"
        :onlyActions="['edit', 'infomodal']"
        :ref="tableRef"
        includeRowSelector
    ></salesmen>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Salesmen from "@/components/tables/Salesmen";
import BaseInput from "@/components/form/BaseInput";
import {mapActions} from "vuex";
import {computed} from "vue";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      args: {},
      repository: "broker",
      resource: "brokers",
      tableRef: "SalesmenTableRef",
      filterName: "filterSalesmen",
      filter: this.initFilter(),
      fields: [
        {
          key: "rowSelector",
          label: "Provvigioni",
          sortable: false,
          thStyle: {
            width: "90px",
          },
        },
        {
          key: "attributables.BUID",
          label: this.getDictionary("attribute_BUID_value", "broker"),
          sortable: true,
          sortKey: "BUID",
        },
        {
          key: "attributables.SURN",
          label: this.getDictionary("attribute_SURN_value", "broker"),
          sortable: true,
          sortKey: "SURN",
        },
        {
          key: "attributables.NAME",
          label: this.getDictionary("attribute_NAME_value", "broker"),
          sortable: true,
          sortKey: "NAME",
        },
        {
          key: "attributables.CNAM",
          label: this.getDictionary("attribute_CNAM_value", "broker"),
          sortable: true,
          sortKey: "CNAM",
        },
        {
          key: "attributables.SEEX",
          label: this.getDictionary("attribute_SEEX_value", "broker"),
          formatter: (value) => (value === "Y" ? "SI" : "NO"),
          sortable: false,
        },
        {
          key: "attributables.REDE",
          label: this.getDictionary("attribute_REDE_value", "broker"),
          formatter: (value) => (value === "Y" ? "SI" : "NO"),
          sortable: false,
        },
        {
          key: "attributables.REPE",
          label: this.getDictionary("attribute_REPE_value", "broker"),
          formatter: (value) => (value === "Y" ? "SI" : "NO"),
          sortable: false,
        },
        {
          key: "attributables.READ",
          label: this.getDictionary("attribute_READ_value", "broker"),
          formatter: (value) => (value === "Y" ? "SI" : "NO"),
          sortable: false,
        },
        {
          key: "status_broker",
          label: this.getDictionary("status_broker", "broker"),
        },
        {
          key: 'fattutto',
          label: this.getDictionary("Flusso contabilità fiscale"),
          sortable: false,
          tdClass: "text-right",
          thClass: "text-right",
        }
      ],
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      args: computed(() => this.args),
    };
  },
  components: {
    Salesmen,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_broker: 0,
          title: null,
          NAME: null,
          SURN: null,
          BUID: null,
        },
      };
      return init;
    },
    commission(item) {
      this.$router.push({
        name: "brokers.pivot",
        params: {id: `${item.id}`},
      });
    },
    onEdit(id, item) {
      this.$router.push({
        name: `brokers.edit0`,
        params: {id: `${id}`, item: item},
      });
    },
    onAdd() {
      this.$router.push({name: `brokers.create0`});
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
          .then(() => {
            this.$refs[this.tableRef].fetch();
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({name, criteria});
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({name, criteria});
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {removePaginationByName: "removeByName"}),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
};
</script>
