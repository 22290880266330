<template>
  <div class="mx-1">
    <b-row>
      <b-col align="left">
        <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
        >
          <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea
        </b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                  name="title"
                  type="text"
                  label="Movimento Vario"
                  v-model="filter.byAttribute.title"
                  placeholder="Inserisci una descrizione"
              />
            </div>
            <div class="col-md-3">
              <base-input
                  name="display"
                  type="text"
                  label="Codice Movimento Vario"
                  v-model="filter.byAttribute.display"
                  placeholder="Inserisci un codice"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
              >Cerca
              </b-button
              >
              <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
              >Reset
              </b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <various-accountings
        @edit="onEdit"
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        @destroy="onDestroy"
        :ref="tableRef"
    ></various-accountings>
  </div>
</template>

<script>
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import VariousAccountings from "@/components/tables/VariousAccountings.vue";
import BaseInput from "@/components/form/BaseInput";
import {mapGetters, mapActions} from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "various_accounting",
      resource: "various_accountings",
      tableRef: "VariousAccountingsTableRef",
      filterName: "filterVariousAccountings",
      filter: this.initFilter(),
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "various_accounting"),
          sortable: true,
          sortKey: "title",
        },
        {
          key: "display",
          label: this.getDictionary("code", "various_accounting"),
          sortable: true,
          sortKey: "display",
        },
        {
          key: "is_anticipated.text",
          label: this.getDictionary("is_anticipated", "various_accounting"),
          sortable: false,
        },
        {
          key: "status_registry.text",
          label: this.getDictionary("status_registry", "various_accounting"),
          sortable: false,
        },
        {
          key: "status_insurer.text",
          label: this.getDictionary("status_insurer", "various_accounting"),
          sortable: false,
        },
        {
          key: "status_broker.text",
          label: this.getDictionary("status_broker", "various_accounting"),
          sortable: false,
        },
        {
          key: 'fattutto',
          label: this.getDictionary("Flusso contabilità fiscale"),
          sortable: false,
        }
      ],
    };
  },
  components: {
    VariousAccountings,
    BaseInput,
  },
  mounted() {
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          display: null,
          title: null,
        },
      };
      return init;
    },

    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: {id: `${id}`},
      });
    },
    onAdd() {
      this.$router.push({name: `${this.resource}.create`});
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
          .then(() => {
            this.$refs[this.tableRef].fetch();
            this.$showSnackbar({
              preset: "success",
              text: "Movimento Vario Eliminato con successo",
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({name, criteria});
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {removePaginationByName: "removeByName"}),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({name, criteria});
    },

    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
    }),
  },
};
</script>
