<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template
            #cell(various_accountings_insurance_ancillaries)="{ item }"
            v-if="items.length"
          >
            {{
              item?.various_accountings?.[0]?.pivot?.insurance_policy_number
                ? item.various_accountings[0].pivot.insurance_policy_number
                : item?.insurance_ancillaries?.[0]?.pivot
                    ?.insurance_policy_number
                ? item.insurance_ancillaries[0].pivot.insurance_policy_number
                : "-"
            }}
          </template>
          <template #cell(last_deposited)="{ item }" v-if="items.length">
            {{
              checkLastDepositedDate(item.entry_details)
                ? moment(checkLastDepositedDate(item.entry_details)).format(
                    "DD/MM/YYYY"
                  )
                : ""
            }}
          </template>
          <template #cell(registry)="{ item }" v-if="items.length">
            <div v-for="(registry, index) in item.registries" :key="index">
              <span
                v-if="
                  !Array.isArray(registry.attributables) &&
                  !registry.attributables.length
                "
                >{{
                  registry.status_registry.value === 1
                    ? registry.attributables.CNAM
                    : registry.attributables.SURN +
                      " " +
                      registry.attributables.NAME
                }}</span
              >
            </div>
          </template>
          <template #cell(positive_deposited)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(positiveDeposited(item)) }}
          </template>
          <template #cell(total_deposited)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(totalDeposited(item)) }}
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            <span v-html="toInfoData(item.brokers[0], 'broker', 0)"></span>
          </template>
          <template #cell(document_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenDocModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(row.item.documents, 'Documenti', [
                  { label: 'Titoli', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  {
                    label: 'Ultimo aggiornamento',
                    key: 'last_update',
                    formatter: (val) => {
                      return moment(val).format('DD/MM/YYYY HH:mm:ss');
                    },
                  },
                ])
              "
            >
              {{ row.item.documents.length }}
            </div>
          </template>
          <template #cell(note_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenNoteModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Creato da',
                      key: 'owner_id',
                      formatter: (val) => retriveOwner(val),
                    },
                    {
                      label: 'Data creazione',
                      key: 'created_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 2)
                  .length
              }}
            </div>
          </template>

          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item)"
                :disabled="
                  !isVariousAccounting(row.item) || hasNegativeDeposit(row.item)
                "
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-row class="table-extra-footer">
        <div class="deposited_yes_colour"></div>
        <p class="label">Aperti</p>
        <div class="deposited_no_colour"></div>
        <p class="label">Parzialmente recuperati</p>
        <div class="deposited_reduced_colour"></div>
        <p class="label">Chiusi</p>
      </b-row>
      <b-modal
        :id="infomodalName"
        @show="onModalShow"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import General from "@/components/accounting/deposit/details/General.vue";
import Deferred from "@/components/accounting/deposit/details/Deferred.vue";
import Appointments from "@/components/accounting/deposit/details/Appointments";
import Notes from "@/components/accounting/deposit/details/Notes";
import Todo from "@/components/accounting/deposit/details/Todo";
import Documents from "@/components/accounting/deposit/details/Documents";
import { toLocaleCurrency } from "@/utils/strings";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  name: "BookEntriesDeferredTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Recupero Acconto" },
        { name: "Deferred", text: "Storico Recuperi" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Documents", text: "Doc" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    General,
    Deferred,
    ButtonGroupTab,
    BaseCheckbox,
    Todo,
    Notes,
    Appointments,
    Documents,
    BaseInputPagination,
  },
  methods: {
    moment,
    toLocaleCurrency,
    onModalShow() {
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      this.setTab({ name: "General", text: "Generale" }, 0);
    },
    customOpenNoteModal(item, index, target) {
      this.tabIndex = 3; // TabIndex ID delle Note
      this.openModal(item, index, target);
    },
    customOpenDocModal(item, index, target) {
      this.tabIndex = 5; // TabIndex ID dei Documenti
      this.openModal(item, index, target);
    },
    positiveDeposited(item) {
      return parseFloat(
        item.entry_details
          .filter((el) => el.treasury.code == "AC" && el.gross > 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    totalDeposited(item) {
      return parseFloat(
        item.entry_details
          .filter((el) => el.treasury.code == "AC")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    checkLastDepositedDate(entry_details) {
      let filteredEntryDetails = entry_details.filter(
        (entry_detail) =>
          entry_detail.treasury.code === "AC" && entry_detail.gross < 0
      );
      if (filteredEntryDetails.length) {
        let lastDeferredDate = "1900-01-01";
        for (const entry_detail of filteredEntryDetails) {
          if (moment(entry_detail.detail_date).isAfter(lastDeferredDate)) {
            lastDeferredDate = entry_detail.detail_date;
          }
        }
        return lastDeferredDate;
      }
      return "";
    },
    customized() {
      let c = {};
      return c;
    },
    rowClass(item) {
      if (item) {
        if (this.totalDeposited(item) === 0) return "deposited-status-2";
        if (
          item.entry_details.find(
            (entry_detail) =>
              entry_detail.treasury.code === "AC" && entry_detail.gross < 0
          )
        )
          return "deposited-status-1";
      }
      return "deposited-status-0";
    },
    hasNegativeDeposit(item) {
      return item.entry_details.some(
        (entry_detail) =>
          entry_detail.treasury.code === "AC" && entry_detail.gross < 0
      );
    },
    isVariousAccounting(item) {
      return item.various_accountings.length;
    },
    retriveOwner(value) {
      if (!value) return "-";
      return (
        this.getUsers().find((user) => user.value === value).username ?? "-"
      );
    },
    ...mapGetters("auth", {
      getUsers: "users",
    }),
  },
};
</script>

<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
/** inserisce un gradiente orizzontale nella prima cella della table */
/* ROSSO */
:deep(.deposited-status-0 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(228, 33, 33, 0.3) 100%
  );
}
/* VERDE */
:deep(.deposited-status-1 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(19, 172, 19, 0.4) 100%
  );
}
/* NEUTRO */
:deep(.deposited-status-2 td:nth-child(2)) {
  background: rgb(255, 255, 255);
}
.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_yes_colour {
  width: 15px;
  height: 15px;
  background: rgba(228, 33, 33, 0.3);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_no_colour {
  width: 15px;
  height: 15px;
  background: rgba(19, 172, 19, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deposited_reduced_colour {
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 1);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>
