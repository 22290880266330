<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
                :name="beFormReport.format.label"
                vid="format"
                :label="beFormReport.format.label"
                v-model="form.format"
                :options="allowedFormatOptions"
                :rules="{ required: true }"
            />
            <base-datepicker
                vid="data_da"
                name="data_da"
                label="Periodo da"
                v-model="form.request_input.data_da"
                :rules="{ required: true }"
            />
            <base-datepicker
                vid="data_a"
                name="data_a"
                label="Periodo a"
                v-model="form.request_input.data_a"
                :rules="{ required: true }"
            />
            <base-select
                vid="formato_sinprecli"
                name="formato_sinprecli"
                label="Livello Dettaglio"
                v-model="form.request_input.formato_sinprecli"
                :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_sinprecli.options
                )
              "
                @select="svuotaValori()"
                :rules="{ required: true }"
            />
            <div v-if="form.request_input.formato_sinprecli === 0">
              <b-button-toolbar>
                <b-button-group>
                  <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="registry_data"
                      :readonly="true"
                      :rules="{ required: true }"
                  />
                  <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                  >
                    <b-icon icon="search"></b-icon
                    >
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <base-select
                  vid="compagnia"
                  name="compagnia"
                  label="Compagnia"
                  v-model="form.request_input.compagnie"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                  :options="getInsurers()"
              />
              <b-button
                  :pressed.sync="myToggleI"
                  variant="light"
                  class="mb-2"
                  @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                  myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 1">
              <base-select
                  vid="gruppo"
                  name="gruppo"
                  :taggable="true"
                  label="Gruppo anagrafico"
                  v-model="form.request_input.gruppo"
                  :options="formatGroups()"
                  @select="mostraMembriGruppo()"
                  :rules="{ required: true }"
              />
              <base-select
                  vid="compagnia"
                  name="compagnia"
                  label="Compagnia"
                  v-model="form.request_input.compagnie"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                  :options="getInsurers()"
              />
              <b-button
                  :pressed.sync="myToggleI"
                  variant="light"
                  class="mb-2"
                  @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                  myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 2">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Polizza"
                      label="Polizza"
                      v-model="inpt_label_policy"
                      :readonly="true"
                      :required="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchPolicy"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_policy"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetPolicyId"
                      title="Elimina Polizza"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
              <quick-search-policy-modal
                ref="quickSearchPolicy"
                @input="handlePolicyModalInput"
              ></quick-search-policy-modal>
            </div>
            <div v-if="form.request_input.formato_sinprecli === 3">
              <base-select
                  vid="compagnia"
                  name="compagnia"
                  label="Compagnia"
                  v-model="form.request_input.compagnie"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                  :options="getInsurers()"
                  :rules="{ required: true }"
              />
              <b-button
                  :pressed.sync="myToggleI"
                  variant="light"
                  class="mb-2"
                  @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                  myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 4">
              <base-select
                  vid="compagnia"
                  name="compagnia"
                  label="Compagnia"
                  v-model="form.request_input.compagnie"
                  :taggable="true"
                  :multiple="false"
                  :closeOnSelect="true"
                  :options="getInsurers()"
                  :rules="{ required: true }"
              />
              <base-select
                  vid="ramo"
                  name="ramo"
                  label="Ramo"
                  v-model="form.request_input.rami"
                  :taggable="true"
                  :multiple="true"
                  :options="getBranches()()"
                  :closeOnSelect="false"
              />
              <b-button
                  :pressed.sync="myToggleB"
                  variant="light"
                  class="mb-2"
                  @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
              >
                {{
                  myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 5">
              <base-select
                  vid="compagnia"
                  name="compagnia"
                  label="Compagnia"
                  v-model="form.request_input.compagnie"
                  :taggable="true"
                  :multiple="false"
                  :closeOnSelect="true"
                  :options="getInsurers()"
                  :rules="{ required: true }"
              />
              <base-select
                  vid="produttore"
                  name="produttore"
                  label="Produttore"
                  v-model="form.request_input.produttori"
                  :options="getSalesmen()"
                  :multiple="true"
                  :closeOnSelect="false"
                  :taggable="salesmen_taggable"
                  :defaultHtmlMultipleSelect="true"
                  @changeTaggable="changeTaggable"
              />
              <b-button
                  :pressed.sync="myToggleS"
                  variant="light"
                  class="mb-2"
                  @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
              >
                {{
                  myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 6">
              <base-select
                  vid="ramo"
                  name="ramo"
                  label="Ramo"
                  v-model="form.request_input.rami"
                  :taggable="true"
                  :multiple="true"
                  :options="getBranches()()"
                  :closeOnSelect="false"
                  :rules="{ required: true }"
              />
              <b-button
                  :pressed.sync="myToggleB"
                  variant="light"
                  class="mb-2"
                  @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
              >
                {{
                  myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 7">
              <base-select
                  vid="produttore"
                  name="produttore"
                  label="Produttore"
                  v-model="form.request_input.produttori"
                  :options="getSalesmen()"
                  :rules="{ required: true }"
                  :taggable="salesmen_taggable"
                  :multiple="true"
                  :closeOnSelect="false"
                  :defaultHtmlMultipleSelect="true"
                  @changeTaggable="changeTaggable"
              />
              <b-button
                  :pressed.sync="myToggleS"
                  variant="light"
                  class="mb-2"
                  @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
              >
                {{
                  myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 8">
              <base-select
                  vid="ramo"
                  name="ramo"
                  label="Ramo"
                  v-model="form.request_input.rami"
                  :taggable="true"
                  :multiple="false"
                  :options="getBranches()()"
                  :closeOnSelect="true"
                  :rules="{ required: true }"
              />
              <base-select
                  vid="produttore"
                  name="produttore"
                  label="Produttore"
                  v-model="form.request_input.produttori"
                  :options="getSalesmen()"
                  :taggable="salesmen_taggable"
                  :multiple="true"
                  :closeOnSelect="false"
                  :defaultHtmlMultipleSelect="true"
                  @changeTaggable="changeTaggable"
              />
              <b-button
                  :pressed.sync="myToggleS"
                  variant="light"
                  class="mb-2"
                  @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
              >
                {{
                  myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div v-if="form.request_input.formato_sinprecli === 9">
              <base-select
                  vid="produttore"
                  name="produttore"
                  label="Produttore"
                  v-model="form.request_input.produttori"
                  :options="getSalesmen()"
                  :rules="{ required: true }"
                  :taggable="true"
                  :multiple="false"
                  :closeOnSelect="true"
              />
              <base-select
                  vid="ramo"
                  name="ramo"
                  label="Ramo"
                  v-model="form.request_input.rami"
                  :taggable="true"
                  :multiple="true"
                  :options="getBranches()()"
                  :closeOnSelect="false"
              />
              <b-button
                  :pressed.sync="myToggleB"
                  variant="light"
                  class="mb-2"
                  @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
              >
                {{
                  myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
          </b-card-text>
          <b-button
              type="submit"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="float-right"
          >
            Salva
          </b-button>
        </b-card>
        <quick-search-registry-modal
            ref="quickSearchRegistry"
            @input="handleRegistryModalInput"
        >
        </quick-search-registry-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import {mapGetters} from "vuex";
import common from "@/components/reports/common.vue";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import {toInfoData} from "@/utils/transforms";

export default {
  extends: common,
  data() {
    return {
      isLoadingAjax: false,
      myToggleS: false,
      myToggleI: false,
      myToggleB: false,
      salesmen_taggable: true,
      registryOptions: [],
      groups: [],
      cliente: null,
      registry_data: null,
      inpt_label_policy: null,
      form: {
        format: null,
        request_input: {
          rami: [],
          gruppo: null,
          polizza: null,
          formato_sinprecli: null,
          compagnie: [],
          produttori: [],
          cliente: null,
          data_a: null,
          data_da: null
        },
      },
    };
  },
  components: {
    BaseRadio,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    QuickSearchRegistryModal,
    QuickSearchPolicyModal
  },
  methods: {
    toInfoData,
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    openQuickSearchPolicy() {
      this.inpt_label_policy = null;
      this.form.request_input.polizza = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    handlePolicyModalInput(value) {
      this.form.request_input.polizza = value.id;
      this.inpt_label_policy = value.number;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    createReport() {
      const fields = ['compagnie', 'rami', 'produttori'];
      fields.forEach(field => {
        if (!Array.isArray(this.form.request_input[field])) {
          this.form.request_input[field] = [this.form.request_input[field]];
        }
      });
      this.$emit("saveReport", this.form);
    },
    svuotaValori() {
      this.form.request_input.gruppo = null;
      this.form.request_input.polizza = null;
      this.form.request_input.compagnie = [];
      this.form.request_input.produttori = [];
      this.form.request_input.cliente = null;
      this.form.request_input.rami = [];
      this.inpt_label_policy = null;
    },
    selectAllSalesmen() {
      this.form.request_input.produttori = this.getSalesmen().map(
          (salesman) => salesman.value
      );
    },
    deselectAllSalesmen() {
      this.form.request_input.produttori = [];
    },
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map(
          (insurer) => insurer.value
      );
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = [];
    },
    selectAllBranches() {
      this.form.request_input.rami = this.getBranches()().map(
          (branch) => branch.value
      );
    },
    deselectAllBranches() {
      this.form.request_input.rami = [];
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
    }),
    openQuickSearchRegistry() {
      this.form.request_input.cliente = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form.request_input.cliente = value.id;
      this.registry_data = this.toInfoData(value, "registryfullname");
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    formatGroups() {
      return this.groups.map((value) => {
        return {
          value: value.id,
          text: value.code + ' - ' + value.title,
        };
      });
    },
    mostraMembriGruppo() {
      this.groups.data.map((group) => {
        if (group.id == this.form.request_input.gruppo) {
          this.members = group.registries;
          this.showMembers = true;
        }
      });
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        let querystring = `byAttribute[title]=${query}&byRegistryGroup&byRegistry&perPage=0`;
        const Repo = RepositoryFactory.get("registry");
        Repo.index(querystring)
            .then((response) => {
              const data = response.data.data;
              // Set registries options
              this.registryOptions = data.map((registry) => {
                return {
                  value: registry.id,
                  text: registry.title,
                  item: registry,
                };
              });
              this.isLoadingAjax = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({preset: "error", text: errMsg});
              this.isLoadingAjax = false;
            });
      }
    },
    onInputRegistry(id) {
      if (id) {
        const found = this.registryOptions.find(
            (option) => option.item.id === id
        );
        if (found) {
          // Registry Groups
          if (found.item.registry_groups.length) {
            this.registryGroupOptions = found.item.registry_groups.map(
                (registry_group) => {
                  return {
                    value: registry_group.id,
                    text: `${registry_group.code}-${registry_group.title}
                  ${
                        registry_group.pivot
                            ? registry_group.pivot.primary.value === "Y"
                                ? " - (Primario)"
                                : ""
                            : ""
                    }`,
                    item: registry_group,
                  };
                }
            );
          } else {
            this.form.request_input.gruppi = [];
            this.form.request_input.clienti = [];
            this.registriesOptions = [];
            this.registryGroupOptions = [];
          }
          // Relative Types
          if (found.item.registries.length) {
            this.relationOptions = found.item.registries.map((registry) => {
              return {
                value: registry.id,
                text:
                    registry.status_registry.value === 1
                        ? `${registry.attributables.CREG}-${registry.pivot.relative_type.male}`
                        : registry.attributables.SEX === "M"
                            ? `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`
                            : `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`,
              };
            });
          } else {
            this.form.request_input.relazioni = [];
            this.relationOptions = [];
          }
        }
      } else {
        this.form.request_input.includi_gruppo = null;
        this.form.request_input.relazioni = [];
        this.form.request_input.gruppi = [];
        this.form.request_input.clienti = [];
        this.registriesOptions = [];
        this.relationOptions = [];
        this.registryGroupOptions = [];
      }
    },
  },
  created() {
    const Repo = RepositoryFactory.get("registry_group");
    Repo.index()
      .then((response) => {
        this.groups = response.data.data;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({preset: "error", text: errMsg});
      });

  }
};
</script>
