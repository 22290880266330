<template>
  <b-modal
    id="copyCommissions"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="">
      <div slot="modal-title" class="modal-title">
        <!-- <b-icon icon="cash"></b-icon> -->
        <span>{{ title }}</span>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-button
          @click="
            reset();
            cancel();
          "
          size="sm"
          variant="outline-secondary"
          >ANNULLA</b-button
        >
        <b-button
          class="btn-outline-lisaweb"
          :disabled="!options.length"
          @click="
            $emit('copy', options);
            reset();
            cancel();
          "
          size="sm"
          variant="outline-success"
          >COPIA</b-button
        >
      </b-form-group>
    </template>
    <b-row>
      <!-- testo libero -->
      <p class="col-md-12 mb-3" v-html="text"></p>
    </b-row>
    <b-row>
      <div class="col-md-8">
        <base-select
          v-model="options"
          name="options"
          label="Produttori"
          :options="salesmen_select"
          :multiple="true"
          :taggable="salesmen_taggable"
          :defaultHtmlMultipleSelect="true"
          :closeOnSelect="false"
          @select="onOption"
          @remove="onOption"
          @changeTaggable="changeTaggable"
        />
        <div>
          <b-button
            :pressed.sync="myToggle"
            variant="light"
            @click="onToggle"
            >{{ myToggle ? "Deseleziona tutti" : "Seleziona tutti" }}</b-button
          >
          <b-button
            :pressed.sync="myTogglePO"
            variant="light"
            @click="myTogglePO ? salesmenOrderByCode() : salesmenOrderByName()"
            >{{
              myTogglePO ? "Ordine per nome" : "Ordina per codice"
            }}</b-button
          >
        </div>
      </div>
      <!-- <div class="form-group col-md-4 align-self-end">
        
      </div> -->
    </b-row>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      options: [],
      myToggle: false,
      myTogglePO: false,
      salesmen_taggable: true,
      salesmen: [],
      salesmen_ordered: [],
      salesmen_select: [],
    };
  },
  components: {
    BaseSelect,
  },
  props: {
    opts: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "Opzioni",
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {},
  beforeMount() {
    this.salesmen = this.opts;
    this.salesmen_select = this.salesmen;
    console.log("opts", this.opts);
    // this.salesmen = this.getSalesmen();
    this.salesmen_ordered = this.getSalesmenOrderedByCode();
    /* */
    // Convert the first list to a Set of values for quick lookup
    const valuesSet = new Set(this.salesmen.map((item) => item.value));
    // Find the extra object(s) in the second list
    // const extraObjects = this.salesmen_ordered.filter(item => !valuesSet.has(item.value));
    // Print the extra object(s)
    // console.log("Extra object(s):", extraObjects);
    this.salesmen_ordered = this.salesmen_ordered.filter((item) =>
      valuesSet.has(item.value)
    );

    /* */

    console.log("salesmen_ordered", this.salesmen_ordered);
  },
  methods: {
    salesmenOrderByName() {
      this.salesmen_select = this.salesmen;
    },
    salesmenOrderByCode() {
      this.salesmen_select = this.salesmen_ordered;
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    onOption() {
      if (this.options.length) {
        this.myToggle = this.options.length === this.opts.length;
      } else {
        this.myToggle = false;
      }
    },
    onToggle() {
      if (this.myToggle) {
        this.options = this.opts.map((e) => e.value);
      } else {
        this.options = [];
      }
    },
    reset() {
      this.options = [];
      this.myToggle = false;
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getSalesmenOrderedByCode: "salesmen_orderd_by_code",
    }),
  },
};
</script>
<style lang="scss" scoped></style>
