<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <div class="btn-group-wrapper">
          <b-button
            class=""
            type="button"
            variant="lisaweb"
            size="sm"
            @click="customOpenManualNoteModal($event.target)"
            title="Visualizza polizze con note manuali"
            >Visualizza polizze con note manuali</b-button
          >
        </div>
        <div class="btn-group-wrapper">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </div>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="number"
                type="text"
                label="Numero"
                v-model="filter.byAttribute.number"
                placeholder="Inserisci un numero polizza"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="number"
                type="text"
                label="Targa"
                v-model="filter.byAttribute.REPL"
                placeholder="Inserisci una targa"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Frazionamento"
                label="Frazionamento"
                :options="payment_splitting"
                v-model="filter.byAttribute.payment_splitting"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="Tipologia"
                label="Tipologia"
                :options="typologies"
                v-model="filter.byTypology.status_policy"
                @select="onTypologySelect"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Status Polizza"
                label="Status Polizza"
                :options="status_policy"
                v-model="filter.byAttribute.status_policy"
                @select="onStatusSelect"
              />
            </div>
            <div class="col-md-3" data-select-all>
              <base-select
                name="companies"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
                :multiple="true"
                :taggable="true"
                :closeOnSelect="false"
                @input="onInputInsurer"
              />
              <b-button
                :pressed.sync="myToggleC"
                variant="light"
                class="mb-2"
                @click="
                  myToggleC ? selectAllCompanies() : deselectAllCompanies()
                "
                >{{
                  myToggleC ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="salesmen"
                label="Produttori"
                :options="salesmen_select"
                :multiple="true"
                :taggable="salesmen_taggable"
                :closeOnSelect="false"
                v-model="filter.byBroker.id"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
              <!-- <div>
                <b-button
                  :pressed.sync="myTogglePO"
                  variant="light"
                  class="mb-2"
                  @click="
                    myTogglePO ? salesmenOrderByCode() : salesmenOrderByName()
                  "
                  >{{
                    myTogglePO ? "Ordine per nome" : "Ordina per codice"
                  }}</b-button
                >
              </div> -->
              <!-- <select
                style="max-width: 100%; min-height: 100px"
                multiple
                v-model="filter.byBroker.id"
              >
                <option
                  v-for="(option, index) in salesmen"
                  :value="option.value"
                  :key="`prod_${index}`"
                >
                  {{ option.text }}
                </option>
              </select> -->
              <!-- v-model="filter.byBrokerEnhanced.salesmen" -->
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="risks"
                label="Tipo"
                :options="risks"
                v-model="filter.byRiskType.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
                @input="onInputRisk"
              />
              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllRisks()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllRisks()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleR"
                variant="light"
                class="mb-2"
                @click="myToggleR ? selectAllRisks() : deselectAllRisks()"
                >{{
                  myToggleR ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="risk_branches"
                :label="getDictionary('risk_branch_id', 'insurance_risk')"
                :options="risk_branches"
                v-model="filter.byRiskBranch.id"
                :multiple="true"
                :taggable="true"
                :closeOnSelect="false"
                @input="onInputBranch"
              />

              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllBranches()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllBranches()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleB"
                variant="light"
                class="mb-2"
                @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
                >{{
                  myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="prdct"
                label="Prodotto"
                :options="prdct"
                v-model="filter.byInsuranceRisk.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllProducts()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllProducts()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleP"
                variant="light"
                class="mb-2"
                @click="myToggleP ? selectAllProducts() : deselectAllProducts()"
                >{{
                  myToggleP ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-input
                name="product_description"
                type="text"
                label="Descrizione Prodotto"
                v-model="filter.byCustom.title.value"
                placeholder="Inserisci la descrizione del Prodotto"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="expired_at"
                label="Data Scadenza dal"
                v-model="filter.byExpiration.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="expired_at"
                label="Data Scadenza al"
                v-model="filter.byExpiration.to"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="covered_at"
                label="Data Copertura dal"
                v-model="filter.byCoverage.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="covered_at"
                label="Data Copertura al"
                v-model="filter.byCoverage.to"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>

          <b-collapse id="collapse-1-inner" class="mt-2">
            <b-row>
              <div class="form-group col-md-3">
                <div>
                  <b-button-toolbar>
                    <b-button-group>
                      <base-input
                        name="Gruppo Anagrafico"
                        label="Gruppo Anagrafico"
                        v-model="form.inpt_label_group"
                        :readonly="true"
                      />
                      <b-button
                        class="mt-4 btn-quick"
                        size="sm"
                        text="Button"
                        variant="lisaweb"
                        title="Ricerca Veloce"
                        @click="openQuickSearchGroup"
                        ><b-icon icon="search"></b-icon
                      ></b-button>
                      <b-button
                        v-if="form.inpt_label_group"
                        class="mt-4 btn-quick ml-1"
                        size="sm"
                        text="Button"
                        variant="lisaweb"
                        @click="resetGroupId"
                        title="Elimina Gruppo"
                        ><b-icon icon="trash"></b-icon
                      ></b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>
              <quick-search-group-modal
                ref="quickSearchGroup"
                @input="handleGroupModalInput"
              ></quick-search-group-modal>
              <div class="form-group col-md-3">
                <span
                  v-if="extra_group_full_info"
                  class="info"
                  v-html="extra_group_full_info"
                >
                </span>
              </div>
              <div class="col-md-3">
                <base-select
                  name="cooperators"
                  label="Collaborazioni"
                  :options="cooperators"
                  :multiple="true"
                  :taggable="true"
                  v-model="filter.byCollaborator.id"
                />
                <!-- v-model="filter.byBrokerEnhanced.cooperators" -->
              </div>
              <div class="col-md-3">
                <!-- <base-input
                  name="mandate_code"
                  type="text"
                  label="Codice Mandato"
                  v-model="filter.byAttribute.mandate_code"
                  placeholder="Inserisci un codice mandato"
                /> -->
                <base-select
                  name="mandate_code"
                  label="Codice Mandato"
                  :options="mandate_code_options"
                  v-model="filter.byAttribute.mandate_code_id"
                  placeholder="Seleziona un codice mandato"
                />
                <!-- :disabled="!filter.byInsurer.id" -->
              </div>
            </b-row>
            <b-row>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="effective_at"
                  label="Data Decorrenza dal"
                  v-model="filter.byValidity.from"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="effective_at"
                  label="Data Decorrenza al"
                  v-model="filter.byValidity.to"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="issued_at"
                  label="Data Emissione dal"
                  v-model="filter.byIssue.from"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="issued_at"
                  label="Data Emissione al"
                  v-model="filter.byIssue.to"
                />
              </div>
            </b-row>
            <b-row>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="suspended_at"
                  label="Data sospensione dal"
                  v-model="filter.bySuspension.from"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="suspended_at"
                  label="Data sospensione al"
                  v-model="filter.bySuspension.to"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="cancelled_at"
                  label="Data storno dal"
                  v-model="filter.byCancellation.from"
                />
              </div>
              <div class="form-group col-md-3">
                <base-datepicker
                  name="cancelled_at"
                  label="Data storno al"
                  v-model="filter.byCancellation.to"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="companies"
                  label="Polizze soggette a regolazione premio"
                  :options="bona"
                  v-model="filter.byAttribute.BONA"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="companies"
                  label="Polizze indicizzate"
                  :options="indx"
                  v-model="filter.byAttribute.INDX"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="note"
                  label="Note"
                  v-model="filter.byTask.task_type"
                  :options="[
                    { value: '2', text: 'Cerca solo polizze con note' },
                  ]"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                />
              </div>
            </b-row>
            <div v-show="customFilters.length > 0">
              <p>Attributi personalizzati</p>
              <b-row>
                <custom-filter
                  v-for="(element, index) in customFilters.filter(
                    (e) => getInputType(e) === 'text'
                  )"
                  :key="`A${index}`"
                  v-model="filter.byCustom[element.key]['value']"
                  :element="element"
                />
                <custom-filter
                  v-for="(element, index) in customFilters.filter(
                    (e) => getInputType(e) !== 'text'
                  )"
                  :key="`B${index}`"
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        // {
        //   code: 'LISPOL',
        //   label: null,
        //   formats: ['csv', 'pdf'],
        // },
        {
          code: 'EXPOINPO',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

    <div class="table-wrapper" data-type="overflow-x">
      <table-policies
        @edit="onEdit"
        @destroy="onDestroy"
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterOn="{
          byRelations: [
            'byInsurer',
            'byInsuranceRisk',
            'byRegistryGroup',
            'byTask',
            'byDocument',
            'byBroker',
            'byCollaborator',
          ],
        }"
        :filterName="filterName"
        :ref="tableRef"
        :onlyActions="['edit', 'destroy', 'infomodal']"
      >
      </table-policies>
    </div>
    <!-- -->
    <b-modal
      :dialog-class="customModalClass"
      :id="customModalName"
      :title="custoModalTitle"
      :size="custoModalSize"
      scrollable
      ok-only
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      no-close-on-backdrop
    >
      <div>
        <b-tabs content-class="pt-1" lazy v-model="tabIndex">
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            @click="customSetTab(tab, index)"
            :title="tab.text"
            :active="index === tabIndex"
            title-item-class="lisaweb"
          >
            <component :is="selected"></component>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <!-- -->
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import TablePolicies from "@/components/tables/Policies";
import BaseInput from "@/components/form/BaseInput";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ManualNotes from "@/components/wallet/policies/details/ManualNotes";
// import ExportsMixin from "@/mixins/ExportsMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
import { getInputType } from "@/utils/forms";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      customModalClass: "modal-note-manuali",
      customModalName: "modalNoteManuali",
      custoModalTitle: "Polizze sostituite con note ad ereditarietà manuale",
      custoModalSize: "xl",
      tabs: [{ name: "ManualNotes", text: "Note Manuali" }],
      isLoading: true,
      exportUrl: null,
      myToggleB: false,
      myToggleC: false,
      myToggleP: false,
      myTogglePO: false,
      myToggleR: false,
      filter: this.initFilter(),
      filterName: "filterPolicy",
      repository: "insurance_policy",
      resource: "insurance_policies",
      tableRef: "PolicyTableRef",
      notes: [],
      documents: [],
      companies: [],
      risk_branches: [],
      risk_branches_tot_length: null,
      mandate_code_options: [],
      risks: [],
      indx: [{ value: "Y", text: "Si" }],
      bona: [{ value: "Y", text: "Si" }],
      products: [],
      salesmen: [],
      salesmen_ordered: [],
      salesmen_select: [],
      salesmen_taggable: true,
      cooperators: [],
      fields: [
        {
          key: "insurer.title",
          value: "insurer.title",
          label: this.getDictionary("title", "insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "number",
          value: "number",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortkey: "byAttribute.number",
        },
        {
          key: "collaborator",
          value: (item) => toInfoData(item.collaborator, "broker"),
          label: this.getDictionary("Polizza in collaborazione"),
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_policy"),
          value: "effective_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "effective_at",
        },
        {
          key: "covered_at",
          label: this.getDictionary("covered_at", "insurance_policy"),
          value: "covered_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "covered_at",
        },
        {
          key: "expired_at",
          label: this.getDictionary("expired_at", "insurance_policy"),
          value: "expired_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "expired_at",
        },
        {
          key: "suspended_at",
          label: this.getDictionary("suspended_at", "insurance_policy"),
          value: (value) => toLocaleDate(value, "DD/MM/YYYY", "00/00/0000"),
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY", "00/00/0000"),
          sortable: true,
          sortKey: "suspended_at",
        },
        {
          key: "gross_premium",
          label: this.getDictionary("gross_premium", "insurance_policy"),
          value: (value) => toLocaleCurrency(value),
          formatter: (value) => toLocaleCurrency(value),
          sortable: true,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "gross_payment_splitting",
          value: (item) =>
            toLocaleCurrency(
              item.gross_premium *
                (12 / (parseInt(item.payment_splitting.value) || 12))
            ),
          label: this.getDictionary("total_gross", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.gross_premium",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "payment_splitting.text",
          value: "payment_splitting.text",
          label: this.getDictionary("payment_splitting", "insurance_policy"),
          sortable: true,
          sortKey: "installments",
        },
        {
          // Ramo
          key: "insurance_risk.risk_branch.title",
          value: "insurance_risk.risk_branch.title",
          label: this.getDictionary("risk_branch_id", "insurance_risk"),
          sortable: true,
          sortKey: "byRiskBranch.code",
        },
        {
          // Prodotto
          key: "insurance_risk",
          value: "insurance_risk.title",
          label: this.getDictionary("insurance_risk"),
        },
        {
          key: "status_policy.text",
          value: "status_policy.text",
          label: this.getDictionary("status_policy", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.status_policy",
        },
        {
          key: "contraente",
          value: (item) =>
            item.registry ? toInfoData(item.registry, "registryfullname") : "",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "primary_group",
          value: (item) =>
            item.registry
              ? item.registry.registry_groups.length &&
                item.registry.registry_groups.find(
                  (registry_group) => registry_group.pivot.primary.value === "Y"
                )
                ? item.registry.registry_groups.find(
                    (registry_group) =>
                      registry_group.pivot.primary.value === "Y"
                  ).code
                : ""
              : "",
          label: this.getDictionary("Gruppo Primario del contraente"),
        },
        {
          key: "broker",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.BUID",
          value: (item) => toInfoData(item.broker, "broker"),
        },
        {
          key: "delay_days",
          label: this.getDictionary("delay_days", "insurance_policy"),
          value: (item) =>
            item.delay_days ? item.delay_days : item.insurance_risk.delay_days,
          sortable: false,
        },
        {
          key: "document_counter",
          label: this.getDictionary("Documenti"),
          value: (item) => (item.documents.length ? item.documents.length : 0),
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          value: (item) =>
            item.tasks.length
              ? item.tasks.filter((task) => task.task_type.value === 2).length
              : 0,
        },
      ],
      payment_splitting: [
        { value: "0", text: "Unico" },
        { value: "1", text: "Mensile" },
        { value: "2", text: "Bimestrale" },
        { value: "3", text: "Trimestrale" },
        { value: "4", text: "Quadrimestrale" },
        { value: "6", text: "Semestrale" },
        { value: "12", text: "Annuale" },
      ],
      status_policy: [
        { value: "0", text: "Non perfezionata" },
        { value: "1", text: "Perfezionata" },
        { value: "2", text: "Sospesa" },
        { value: "3", text: "Sostituita" },
        { value: "10", text: "Stornata" },
        { value: "4", text: "Annullata" },
        { value: "5", text: "Scaduta" },
        { value: "6", text: "Svincolata" },
        { value: "7", text: "Abbandonata" },
        { value: "8", text: "Disdetta" },
        { value: "9", text: "Nuova viva" },
      ],
      typologies: [
        { value: "0,1,2", text: "Polizze attive" },
        { value: "3,4,5,6,7,8,9,10", text: "Polizze estinte" },
      ],
      group_data: null,
      extra_group_full_info: null,
      form: {
        inpt_label_group: "",
        quick_value: "",
      },
      selected: "ManualNotes", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
      dbRowData: null, // used to pass the actual record to tabs
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    CustomFilter,
    TablePolicies,
    BaseDatepicker,
    // ButtonGroupTable,
    ExportOptions,
    QuickSearchGroupModal,
    ManualNotes,
  },
  mounted() {
    this.companies = this.getInsurers();
    this.risk_branches = this.getBranches()(this.filter.byInsurer.id);
    this.risk_branches_tot_length = this.risk_branches.length;
    this.risks = this.getRisks();
    this.salesmen = this.getSalesmen();
    this.salesmen_ordered = this.getSalesmenOrderedByCode();
    this.salesmen_select = this.salesmen;
    // this.cooperators = this.getCooperators();
    // this.mandate_code_options = this.getMandateCodes()(null);
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    this.isLoading = true;
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("cooperators")) {
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.cooperators = this.getCooperators();
    }
    if (!this.hasLoadedResource("mandate_codes")) {
      resources.push(
        this.$store
          .dispatch(`${path}mandate_codes`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.mandate_code_options = this.getMandateCodes()(null);
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.cooperators = this.getCooperators();
          this.mandate_code_options = this.getMandateCodes()(null);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.unloadMsg();
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
    // const defaultCountryId = this.getCountries().find(
    //   (country) => country.text == this.defaultCountryName
    // ).value;
    // this.opt_provinces = this.getProvinces()(defaultCountryId);
  },
  methods: {
    toInfoData,
    getInputType,
    salesmenOrderByName() {
      this.salesmen_select = this.salesmen;
    },
    salesmenOrderByCode() {
      this.salesmen_select = this.salesmen_ordered;
    },
    customSetTab(tab, index) {
      console.log("select tab: ", tab.name);
      this.customSelTab = tab.name;
      this.tabIndex = index;
    },
    customOpenManualNoteModal(target) {
      this.$bvModal.show(this.customModalName, target);
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byAttribute: {
          number: null,
          mandate_code_id: null,
          payment_splitting: null,
          status_policy: null,
          covered_at: null,
          effective_at: null,
          expired_at: null,
          issued_at: null,
          REPL: null,
          BONA: null,
          INDX: null,
        },
        byCustom: {
          title: {
            value: null,
            likewise: 3,
            filter: "byAttribute",
          },
        },
        byCoverage: {
          to: null,
          from: null,
        },
        byExpiration: {
          to: null,
          from: null,
        },
        // byBrokerEnhanced: {
        //   salesmen: [],
        //   cooperators: [],
        // },
        byBroker: {
          id: null,
        },
        byCollaborator: {
          id: null,
        },
        byRegistryGroup: {
          code: null,
          byBroker: {
            id: null,
          },
        },
        byTask: {
          task_type: null,
        },
        byIssue: {
          to: null,
          from: null,
        },
        byValidity: {
          to: null,
          from: null,
        },
        bySuspension: {
          to: null,
          from: null,
        },
        byCancellation: {
          to: null,
          from: null,
        },
        byInsurer: {
          id: [],
        },
        byInsuranceRisk: {
          id: null,
        },
        byRiskBranch: {
          id: null,
        },
        byRiskType: {
          id: null,
        },
        byTypology: {
          status_policy: "0,1,2",
        },
      };
      return init;
    },
    onInputInsurer(insurer) {
      // insurer è un array
      this.filter.byInsurer.id = insurer;
      this.filter.byRiskBranch.id = null;
      if (
        insurer?.length &&
        this.risk_branches_tot_length !== this.risk_branches.length
      ) {
        let full_risk_branches = [];
        insurer.forEach((el) => {
          full_risk_branches = [
            ...full_risk_branches,
            ...this.getBranches()(el),
          ];
        });
        this.risk_branches = [
          ...new Set(full_risk_branches.map(JSON.stringify)),
        ].map(JSON.parse);
      } else {
        this.risk_branches = insurer.length
          ? this.getBranches()(insurer)
          : this.getBranches()();
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
      // reset mandate_code_id
      this.filter.byAttribute.mandate_code_id = null;
      // carica (o svuota) la tendina dei mandati
      this.mandate_code_options = [];
      this.mandate_code_options = this.getMandateCodes()(insurer);
    },
    deselectAllBranches() {
      this.filter.byRiskBranch.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllCompanies() {
      this.filter.byInsurer.id = [];
      this.filter.byRiskType.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
      this.renderAllBranches();
    },
    deselectAllRisks() {
      this.filter.byRiskType.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllProducts() {
      this.filter.byInsuranceRisk.id = [];
    },
    selectAllBranches() {
      this.filter.byRiskBranch.id = this.risk_branches.map(
        (branch) => branch.value
      );
    },
    selectAllRisks() {
      this.filter.byRiskType.id = this.risks.map((risk) => risk.value);
    },
    selectAllCompanies() {
      this.filter.byInsurer.id = this.companies.map((company) => company.value);
      this.renderAllBranches();
    },
    renderAllBranches() {
      if (this.filter.byInsurer.id.length === 0) return;
      this.risk_branches = this.getBranches()();
    },
    selectAllProducts() {
      this.filter.byInsuranceRisk.id = this.products.map(
        (product) => product.value
      );
    },
    onInputBranch(branches) {
      if (!branches.length) {
        this.filter.byRiskBranch.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        branches,
        this.filter.byRiskType.id
      );
    },
    onInputRisk(risks) {
      if (!risks.length) {
        this.filter.byRiskType.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        risks
      );
    },
    onStatusSelect(val) {
      if (val) this.filter.byTypology.status_policy = null;
    },
    onTypologySelect(val) {
      if (val) this.filter.byAttribute.status_policy = null;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id, item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}`, item: item },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Polizza Eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      // preparo il criterio col filtro fittizio
      // process the byTypology filter -> transform it as byAttribute
      let byA = this.filter.byAttribute.status_policy;
      let byT = this.filter.byTypology.status_policy;
      // NOTA: per spezzare la reattività del "filter" usare JSON.parse(JSON.stringify()) !!!
      // altrimenti criteria.byAttribute.status_policy = byT imposta anche this.filter.byAttribute.status_policy = byT
      // e i v-model delle 2 select vanno a ramengo...
      let criteria = JSON.parse(JSON.stringify(this.filter));
      if (byT && !byA) {
        criteria.byAttribute.status_policy = byT;
        criteria.byTypology.status_policy = null;
      }
      // store the filter
      this.saveFilterByName({ name, criteria });
      let extra_info = this.extra_group_full_info;
      this.saveFilterExtraGroupByName({ name, extra_info });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      /* */
      criteria = {};
      ["quickSearchGroup"].forEach((element) => {
        criteria[element] = {
          label: "",
          formLabel: "",
          data: null,
          formData: null,
        };
      });
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      /* */
      this.removePaginationByName(name);
      // fetch (data)
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetGroupId();
      this.myToggleR = false;
      this.myToggleB = false;
      this.myToggleP = false;
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeFilterExtraGroupByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.extra_group_full_info = this.toInfoData(
        this.group_data,
        "registry_group_full"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
      saveFilterExtraGroupByName: "saveExtraGroupByName",
      removeFilterExtraGroupByName: "removeExtraGroupByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("filters", { loadFilterByName: "loadByName" }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getSalesmen: "salesmen",
      getSalesmenOrderedByCode: "salesmen_orderd_by_code",
      getCooperators: "cooperators",
      getMandateCodes: "mandate_codes",
    }),
  },
  computed: {
    customSelTab: {
      set(value) {
        this.selected = value;
      },
      get() {
        return this.selected;
      },
    },
    prdct() {
      return this.products;
    },
  },
};
</script>
