// import { showSnackbar } from "@/utils/messages";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import EventCreateModal from "@/components/modals/eventCreate";
import EventChangeModal from "@/components/modals/eventChange";
import EventShowModal from "@/components/modals/eventShow";
import { mapGetters } from "vuex";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";

import moment from "moment";
moment.locale("it");

const statusCSS = {
  0: "task-status-0", // "non selezionato"
  1: "task-status-1", // "non iniziato"
  2: "task-status-2", // "in lavorazione"
  3: "task-status-3", // "completato"
};
// Moved to ShortcutMixin
// const allTaskables = {
//   brokers: {
//     0: {
//       routeName: "brokers.index0",
//       filterName: "filterSalesmen",
//       hash: null,
//       label: "Produttori",
//     },
//     1: {
//       routeName: "brokers.index1",
//       filterName: "filterCooperator",
//       hash: null,
//       label: "Collaboratori",
//     },
//   },
//   claims: {
//     routeName: "module.PFOLIO",
//     filterName: "filterClaim",
//     hash: "#Claims",
//     label: "Sinistri",
//   },
//   agency_contacts: {
//     routeName: "module.PFOLIO",
//     filterName: "filterContact",
//     hash: "#AgencyContacts",
//     label: "Contatti di Agenzia",
//   },
//   insurance_policies: {
//     routeName: "module.PFOLIO",
//     filterName: "filterPolicy",
//     hash: "#Policies",
//     label: "Polizze",
//   },
//   registries: {
//     routeName: "module.PFOLIO",
//     filterName: "filterRegistry",
//     hash: "#Registries",
//     label: "Anagrafiche",
//   },
//   registry_groups: {
//     routeName: "module.PFOLIO",
//     filterName: "filterRegistryGroup",
//     hash: "#RegistryGroups",
//     label: "Gruppi",
//   },
// };
// const allTaskTabs = {
//   0: "#Appointments",
//   1: "#Todo",
//   2: "#Notes",
// };

// native event props require conversion from / to backend
const replaceMap = {
  started_at: "start",
  ended_at: "end",
};

export default {
  mixins: [ConfirmModalMixin, FormMixin, ShowMixin, ShortcutMixin],
  data() {
    return {
      fromCalendar: true, // handle the origin of the evt/inf object: in Dashboard must be true
      // taskType: null,
      selectInfo: {},
      create_appointment: false,
      // changeModalRef: "modaleModificaTask",
    };
  },
  components: {
    EventCreateModal,
    EventChangeModal,
    EventShowModal,
  },
  methods: {
    initEvent() {
      return {
        id: null,
        title: null,
        start: null,
        end: null,
        // startStr: null,
        // endStr: null,
        allDay: false,
        startTime: "",
        endTime: "",
        // extendeProps
        description: "",
        users: null, // IMPORTANT: must be set for the model to work
        status_task: null,
        expired_at: null,
        histories: [],
        // last_update: null,
        owner_id: null,
        owner: null,
        is_public: null,
        task_type: null,
        recurrence_type: null,
        frequencyEnd: null,
        toDateFrequency: null,
        recurrence_end_date: null,
        memo: null,
        memo_days: null,
        // taskable_id: null,
        // taskable: null,
        // fromCalendar: true,
      };
    },
    initDefaultFormValues(args) {
      // this.form[this.repository].task_type = this.taskType;
      this.form[this.repository].task_type = args.task_type;
      // this.form[this.repository].status_task = 0;
      // this.form[this.repository].taskable_id = this.taskable_id;
      // this.form[this.repository].taskable = this.taskable;
      // this.form[this.repository].is_public = "Y";
    },
    viewTask(task, defaultFromCalendar) {
      // console.debug("viewTask");
      // REVIEW: shall we call api/tasks show each time?
      // let qs = "relations/byHistory";
      // this.fetchShowForm(this.repository, task.id, qs)
      this.fetchShowForm(this.repository, task.id)
        .then((response) => {
          // set the taskType for the modals
          this.taskType = response.data.data.task_type.value;
          this.selectedEvent = this.initEvent();
          this.fromCalendar = defaultFromCalendar ?? false;
          const firstResponse = response.data.data;
          /* */
          let queryString = `byAttribute[id]=${task.id}&byHistory`;
          const Repo = RepositoryFactory.get(this.repository);
          Repo.index(queryString)
            .then((response) => {
              console.log("response", response);
              const histories = response?.data?.data?.[0]?.histories;
              firstResponse.histories =
                histories && histories.length ? histories.pop() : [];
              this.selectInfo = this.convertTaskToCalendarEvent(firstResponse); // this.tasks.find( task => task.id === id);
              this.selectedEvent = this.selectInfo; // QUESTA E' FONDAMENTALE
              this.$bvModal.show("eventShowModal");
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: `${errMsg}` });
            });
          // ?byAttribute[id]=1&byHistory
          /* */
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.formLoaded = true;
        });
      // this.selectedEvent = this.initEvent();
      // this.fromCalendar = false;
      // this.selectInfo = this.convertTaskToCalendarEvent(task); // this.tasks.find( task => task.id === id);
      // this.selectedEvent = this.selectInfo; // QUESTA E' FONDAMENTALE
      // this.$bvModal.show("eventShowModal");
    },
    // refactored
    createTask(task_type = 0) {
      this.selectInfo = {}; // QUESTA E' FONDAMENTALE
      this.fromCalendar = false;
      // this.taskType = 1;
      // TODO: completare con taskable_id
      // console.debug("createTask");
      this.form[this.repository] = this.initEvent();
      this.initDefaultFormValues({ task_type });
      // if (taskable) {
      //   this.selectedEvent.taskable = taskable;
      // }
      this.$bvModal.show("eventCreateModal");
    },
    // backend data and fullcalenda.io event data differs: need conversion tools
    convertTaskToCalendarEvent(task) {
      // task = this.setAllDay(task);
      // task = this.replaceKeyInObjectArray([task], replaceMap)[0];

      let {
        description,
        status_task,
        task_type,
        // taskable,
        expired_at,
        is_public,
        owner,
        owner_id,
        users,
        histories,
        created_at,
        // last_update,
        recurrence_type,
        frequencyEnd,
        toDateFrequency,
        recurrence_end_date,
        memo,
        memo_days,
        ...rest
      } = task;
      rest.extendedProps = {
        description,
        status_task,
        task_type,
        expired_at,
        is_public,
        owner,
        owner_id,
        users,
        histories,
        created_at,
        recurrence_type,
        frequencyEnd,
        toDateFrequency,
        recurrence_end_date,
        memo,
        memo_days,
        // last_update,
        // taskable,
      };

      // rest.allDay = false;
      // WARNING mantenere description, status, users, etc come attributo "principale" (oltre che extendeProps) per il v-model sulle modali edit e create
      rest.description = description;
      rest.status_task = status_task; // REVIEW: when the editForm api will be called, redefine it!!!
      rest.task_type = task_type;
      rest.users = users;
      rest.is_public = is_public;
      rest.owner_id = owner_id;
      rest.owner = owner ?? {};
      rest.owner.date = created_at;
      rest.owner.value = owner_id;
      rest.histories = "id" in histories ? histories : {};
      if ("id" in histories) rest.histories.value = histories.id;
      // rest.last_update = { value: last_update };
      rest.expired_at = expired_at;
      rest.recurrence_type = recurrence_type;
      rest.frequencyEnd = frequencyEnd;
      rest.toDateFrequency = toDateFrequency;
      rest.recurrence_end_date = recurrence_end_date;
      rest.memo = memo;
      rest.memo_days = memo_days;

      // aggiungo il flag che mi ricorda se è un task (fromCalendar = false)
      // rest.fromCalendar = false;
      // aggiungo classNames con valore testuale, lasciando status_task col valore numerico
      rest = this.setClassNames(rest);
      // rest = this.replaceKeyInObjectArray([rest], replaceMap);
      // handle allTaskables, if present
      for (const t in this.allTaskables) {
        if (task[t] && task[t].length) {
          let conf;
          let taskable_id;
          let taskable;
          let routeName;
          let hash;
          let filterName;
          let queryHash;
          if (t === "brokers") {
            let status_broker = task[t].status_broker.value;
            conf = this.allTaskables[t][status_broker];
          } else {
            conf = this.allTaskables[t];
          }
          taskable_id = task[t][0].id;
          taskable = task[t].formatted_title || conf.label;
          routeName = conf.routeName;
          hash = conf.hash;
          filterName = conf.filterName;
          queryHash = this.allTaskTabs[task.task_type.value];
          rest.extendedProps.taskable_id = taskable_id;
          rest.extendedProps.taskable = taskable;
          rest.extendedProps.link = () => {
            // this.$router.push({ name: "registries.edit", params: { id: `${id}` } });
            this.shortcut(
              routeName,
              null,
              hash,
              filterName,
              { id: taskable_id },
              {
                click: "openModal",
                id: taskable_id,
                modalName: "modal",
                hash: queryHash,
              },
              null,
              task[t][0]
            );
          };
          break;
        }
      }
      rest = this.setAllDay(rest);
      rest = this.replaceKeyInObjectArray([rest], replaceMap)[0];
      // aggiungo le prop native dell'evento per il v-model delle modali
      rest.fromDate = "";
      rest.toDate = "";
      // rest.start = "";
      // rest.end = "";
      return rest;
    },
    convertDataToCalendarEvents(data) {
      return this.replaceKeyInObjectArray(data, replaceMap);
    },
    convertCalendarEventToData(event) {
      return this.replaceKeyInObjectArray([event], this.swap(replaceMap))[0];
    },
    getCalendarViewActiveStart() {
      // return activeStart's year, month and day.
      // activeStart: A Date that is the first visible day.
      // In month view, this value is often before the 1st day of the month,
      // because most months do not begin on the first day-of-week.
      let view = this.$refs.fullCalendar.getApi().view;
      let month = moment(view.activeStart).format("M");
      let day = moment(view.activeStart).format("D");
      let year = moment(view.activeStart).format("YYYY");
      return {
        day,
        month,
        year,
      };
    },
    isExpiring(obj, interval = 7) {
      // DEBUG
      // obj.expired_at = "2021-08-31";
      if (!obj.expired_at) return false;
      let expiration = moment(
        obj.expired_at || obj.extendedProps.expired_at
      ).format("YYYY-MM-DD");
      let current_date = moment().format("YYYY-MM-DD");
      let days = moment(expiration).diff(current_date, "days");
      return days > 0 && days < interval;
    },
    setDraggable(obj) {
      const isEventEditable =
        obj?.owner_id === this?.$store?.state?.auth?.user?.id ? true : false;
      obj.startEditable = isEventEditable;
      obj.durationEditable = isEventEditable;
      return obj;
    },
    setClassNames(obj) {
      // const STATUS = {
      //   0: "task-status-0", // "non selezionato"
      //   1: "task-status-1", // "non iniziato"
      //   2: "task-status-2", // "in lavorazione"
      //   3: "task-status-3", // "completato"
      // };
      if (obj.extendedProps) {
        obj.classNames = [statusCSS[obj.extendedProps.status_task.value]] || [
          "task-status-0",
        ];
      } else {
        obj.classNames = [statusCSS[obj.status_task.value]] || [
          "task-status-0",
        ];
      }
      // set expiring style
      if (this.isExpiring(obj)) {
        obj.classNames.push("task-expiring");
      }
      return obj;
    },
    setAllDay(obj) {
      // per il BE, allDay deve iniziare alle 00:00:00 e finire alle 23:59:59
      let s = obj.started_at || obj.start;
      let e = obj.ended_at || obj.end;

      if (!s && !e) {
        obj.allDay = false;
      } else {
        obj.allDay =
          moment(s).format("MM") === moment(e).format("MM") &&
          moment(s).format("DD") === moment(e).format("DD") &&
          moment(s).format("YYYY") === moment(e).format("YYYY") &&
          moment(s).format("HH:mm:ss") === "00:00:00" &&
          moment(e).format("HH:mm:ss") === "23:59:59"
            ? true
            : false;
      }
      return obj;
    },
    getClassNames(task) {
      return task.classNames.join(" ") || "task-status-0";
    },
    swap(json) {
      let ret = {};
      for (const key in json) {
        ret[json[key]] = key;
      }
      return ret;
    },
    addKeyInObjectArray(a, r) {
      let ret = a[0];
      for (const [k1, k2] of Object.entries(r)) {
        ret[k2] = ret[k1];
      }
      return [ret];
    },
    replaceKeyInObjectArray(a, r) {
      // let deepcopy = JSON.parse(JSON.stringify(a));
      return a.map((o) =>
        Object.keys(o)
          .map((key) => ({ [r[key] || key]: o[key] }))
          .reduce((a, b) => Object.assign({}, a, b))
      );
    },
    handleDateSelect(selectInfo) {
      this.fromCalendar = true;
      // this.taskType = 0;
      this.selectInfo = selectInfo;
      // set selectInfo.start and selectInfo.end datetime to splitted into separated date and time values

      // this.form[this.repository] = this.initEvent();
      // this.form[this.repository].startTime = selectInfo.start
      //   ? moment(selectInfo.start).format("HH:mm")
      //   : "";
      // this.form[this.repository].endTime = selectInfo.end
      //   ? moment(selectInfo.end).format("HH:mm")
      //   : "";
      // this.form[this.repository].fromDate = selectInfo.start
      //   ? moment(selectInfo.start).format("YYYY-MM-DD")
      //   : "";
      // this.form[this.repository].toDate = selectInfo.end
      //   ? moment(selectInfo.end).format("YYYY-MM-DD")
      //   : "";

      let tmpObj = {};
      tmpObj = this.initEvent();

      tmpObj.startTime = selectInfo.start
        ? moment(selectInfo.start).format("HH:mm")
        : "";
      tmpObj.endTime = selectInfo.end
        ? moment(selectInfo.end).format("HH:mm")
        : "";
      tmpObj.fromDate = selectInfo.start
        ? moment(selectInfo.start).format("YYYY-MM-DD")
        : "";
      // tmpObj.toDate = selectInfo.end
      //   ? moment(selectInfo.end).format("YYYY-MM-DD")
      //   : "";
      // NOTE: fullcalendar imposta end sul giorno successivo a quello clickato
      // REVIEW se si vuole impostare start e end uguali:
      tmpObj.toDate = tmpObj.fromDate;

      tmpObj.is_public = "N";
      tmpObj.task_type = 0;

      this.form[this.repository] = {
        ...tmpObj,
      };

      this.$bvModal.show("eventCreateModal");
    },
    handleEventClick(clickInfo) {
      this.fromCalendar = true;
      // this.taskType = 0;
      // this.selectInfo = clickInfo.event;
      // REVIEW: shall we call api/tasks show each time? YES.
      this.fetchShowForm(this.repository, clickInfo.event.id)
        .then((response) => {
          let data = response.data.data;
          /* */
          const firstResponse = response.data.data;
          let queryString = `byAttribute[id]=${clickInfo.event.id}&byHistory`;
          const Repo = RepositoryFactory.get(this.repository);
          Repo.index(queryString)
            .then((response) => {
              console.log("response", response);
              const histories = response?.data?.data?.[0]?.histories;
              firstResponse.histories =
                histories && histories.length ? histories.pop() : [];
              this.selectInfo = this.convertTaskToCalendarEvent(firstResponse); // this.tasks.find( task => task.id === id);
              this.selectInfo = this.setAllDay(this.selectInfo);
              this.selectInfo.task_type = data.task_type;
              console.debug("handleEventClick selectInfo: ", this.selectInfo);
              this.selectedEvent = this.selectInfo; // QUESTA E' FONDAMENTALE
              this.$bvModal.show("eventShowModal");
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: `${errMsg}` });
            });
          // ?byAttribute[id]=1&byHistory
          /* */

          // set the taskType for the modals
          // this.taskType = response.data.data.task_type.value;

          // this.selectedEvent = this.initEvent();
          // this.fromCalendar = false;
          /*        this.selectInfo = this.convertTaskToCalendarEvent(data); // this.tasks.find( task => task.id === id);
          this.selectInfo = this.setAllDay(this.selectInfo);
          // set the taskType for the modals
          this.selectInfo.task_type = data.task_type;

          console.debug("handleEventClick selectInfo: ", this.selectInfo);
          // this.selectInfo = this.toCalAllDay(this.selectInfo);
          this.selectedEvent = this.selectInfo; // QUESTA E' FONDAMENTALE
          this.$bvModal.show("eventShowModal");
*/
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.formLoaded = true;
        });
      // this.resetTime();
      // this.selectedEvent = this.initEvent();
      // this.$bvModal.show("eventShowModal");
    },
    // refactored
    // selInf = {id : 1}
    handleChangeClick(selInf = null) {
      if (!selInf) {
        selInf = this.selectInfo;
      }
      // sostituire this.selectInfo con selinf
      let id = selInf.id;
      const owner_id = selInf?.owner_id;
      // let allDay = selInf.allDay;
      let tmpObj = {};
      tmpObj = this.initEvent();
      // tmpObj.allDay = allDay;
      if (selInf.allDay != undefined) {
        tmpObj.allDay = selInf.allDay;
      }
      this.fetchEditForm(this.repository, id)
        .then(() => {
          tmpObj.id = id;

          this.form.update_future_recurrence = true;

          this.form[this.repository] = this.setAllDay(
            this.form[this.repository]
          );

          this.beEditForm[this.repository] = this.convertDataToCalendarEvents([
            this.beForm[this.repository],
          ])[0];

          // TODO: this MUST be fixed backend side!!!
          // BUG: in backend response, "users" object is missing the "value" prop
          // this.beEditForm[this.repository].users.value = [1];
          // BUG: due to previous bug, the mixin setFormValues method was unable to set "users" value
          // this.form[this.repository].users = [1];

          // controllo beForm
          tmpObj.ownerId =
            this?.selectInfo?.extendedProps?.owner?.id ?? owner_id;
          tmpObj.startTime = this.beForm[this.repository].started_at.value
            ? moment(
                new Date(this.beForm[this.repository].started_at.value)
              ).format("HH:mm")
            : "";
          tmpObj.endTime = this.beForm[this.repository].started_at.value
            ? moment(
                new Date(this.beForm[this.repository].ended_at.value)
              ).format("HH:mm")
            : "";
          tmpObj.fromDate = this.beForm[this.repository].started_at.value
            ? moment(
                new Date(this.beForm[this.repository].started_at.value)
              ).format("YYYY-MM-DD")
            : "";
          tmpObj.toDate = this.beForm[this.repository].started_at.value
            ? moment(
                new Date(this.beForm[this.repository].ended_at.value)
              ).format("YYYY-MM-DD")
            : "";

          tmpObj.update_future_recurrence =
            this?.selectInfo?.task_recurrence_id ?? null;

          this.form[this.repository] = {
            ...tmpObj,
            ...this.form[this.repository],
          };

          console.debug("handleChangeClick form: ", this.form[this.repository]);

          // call modal method to properly set the users list
          // this.$refs[this.changeModalRef].setUsers(
          //   this.beForm[this.repository].users.options
          // );
          // this.$refs[this.changeModalRef].beFormUpdate = this.beForm;
          // this.$refs[this.changeModalRef].setRules(this.beRules["task"]);

          this.formEditLoaded = true;
          this.$bvModal.show("eventChangeModal");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    // handleEventDrop(draggedEvent) {
    //   console.log("DROP: ", draggedEvent.event);
    //   console.log("Event ID:", draggedEvent.event.id);
    //   // need to update the day:
    //   console.log("dropped start:", draggedEvent.event.start);
    //   console.log("dropped:", draggedEvent.event.end);
    // },

    testhandleEventDrop(event, dayDelta, minuteDelta, allDay, revertFunc) {
      alert(
        event.title +
          " was moved " +
          dayDelta +
          " days and " +
          minuteDelta +
          " minutes."
      );

      if (allDay) {
        alert("Event is now all-day");
      } else {
        alert("Event has a time-of-day");
      }

      if (!confirm("Are you sure about this change?")) {
        revertFunc();
      }
    },

    handleEventDrop(draggedEvent /*, delta, revertFunc, jsEvent, ui, view*/) {
      console.log("draggedEvent", draggedEvent);
      /**
       * event is an Event Object that hold the event’s information (date, title, etc). Call hasTime on the event’s start/end to see if it has been dropped in a timed or all-day area (more info).
        delta is a Duration Object that represents the amount of time the event was moved by. Available in version 2.0.1 and later.
        revertFunc is a function that, if called, reverts the event’s start/end date to the values before the drag. This is useful if an ajax call should fail.
        jsEvent holds the jQuery event with low-level information such as mouse coordinates.
        ui holds an empty object. Before version 2.1, the jQuery UI object.
        view holds the current View Object.
       */
      // this.fromCalendar = true;
      // this.taskType = 0;
      // console.log("DROP: ", draggedEvent.event);
      // console.log("Event ID:", draggedEvent.event.id);
      // need to update the day:
      // console.log("dropped start:", draggedEvent.event.start);
      // let start = draggedEvent.event.start;
      // let end = draggedEvent.event.end;
      let start = moment(draggedEvent.event.start);
      let end = moment(draggedEvent.event.end);
      // console.log("dropped end:", draggedEvent.event.end);
      let obj = this.calendarOptions.events.find(
        (event) => event.id === parseInt(draggedEvent.event.id)
      );
      if (obj) {
        this.calendarOptions.events = this.calendarOptions.events.filter(
          (event) => event.id !== obj.id
        );
        // aggiorno gli estremi temporali dell'evento
        // console.log("obj start:", obj.start);
        // console.log("obj end:", obj.end);
        // obj.start = moment(obj.start, "YYYY-MM-DD HH:mm:ss")
        //   .set({
        //     year: start.get("year"),
        //     month: start.get("month"),
        //     date: start.get("date"),
        //   })
        //   .format("YYYY-MM-DD HH:mm:ss");
        // console.log("obj new start1:", obj.start);
        obj.start = start.format("YYYY-MM-DD HH:mm:ss");
        obj.end = end.format("YYYY-MM-DD HH:mm:ss");
        // obj.start = draggedEvent.event.start;
        // obj.end = draggedEvent.event.end;
        // console.log("obj new start:", obj.start);
        // console.log("obj new end:", obj.end);

        obj.startTime = start ? start.format("HH:mm") : "";
        obj.endTime = end ? end.format("HH:mm") : "";
        obj.fromDate = start ? start.format("YYYY-MM-DD") : "";
        obj.toDate = end ? end.format("YYYY-MM-DD") : "";
        // salvo nel BE
        // set the form as if it were changed by modal eventChange...
        this.form[this.repository] = { ...this.form[this.repository], ...obj };
        this.putEvent(obj);
      }
    },
    handleEventAllow(dropLocation, draggedEvent) {
      /**
       * The dropLocation object will have the following properties:
        start (a Moment)
        end exclusive end date/time (a Moment)
        resourceId if you are using a Resource View
       */
      console.log("resourceId: ", dropLocation.resourceId);
      if (draggedEvent.id === "999") {
        return dropLocation.start.isAfter("2016-01-01"); // a boolean
      } else {
        return true;
      }
    },
    handleEventResize(eventResized) {
      let start = moment(eventResized.event.start);
      let end = moment(eventResized.event.end);
      let obj = this.calendarOptions.events.find(
        (event) => event.id === parseInt(eventResized.event.id)
      );
      if (obj) {
        this.calendarOptions.events = this.calendarOptions.events.filter(
          (event) => event.id !== obj.id
        );
        obj.start = start.format("YYYY-MM-DD HH:mm:ss");
        obj.end = end.format("YYYY-MM-DD HH:mm:ss");
        obj.startTime = start ? start.format("HH:mm") : "";
        obj.endTime = end ? end.format("HH:mm") : "";
        obj.fromDate = start ? start.format("YYYY-MM-DD") : "";
        obj.toDate = end ? end.format("YYYY-MM-DD") : "";
        this.form[this.repository] = { ...this.form[this.repository], ...obj };
        this.putEvent(obj);
      }
    },
    cancelEventCreation() {
      this.closeCreationDialog();
    },
    closeCreationDialog() {
      this.$bvModal.hide("eventCreateModal");
      this.selectedEvent = this.initEvent();
      // this.fromCalendar = true;
    },
    cancelEventChange() {
      this.closeChangeDialog();
    },
    closeChangeDialog() {
      this.$bvModal.hide("eventChangeModal");
      this.selectedEvent = this.initEvent();
      this.formEditLoaded = false;
      // this.fromCalendar = true;
    },
    onAddAppointment(val) {
      console.log("Val: ", val);
      this.create_appointment = val;
    },
    destroyEvent(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$emit("fetch");
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Nota eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    // refactored
    storeEvent() {
      // save new event/task
      let id;
      let title = this.form[this.repository].title;
      let fromDate;
      let toDate;

      // se all day ma la start non c'è...
      if (
        this.form[this.repository].allDay &&
        !this.form[this.repository].fromDate
      ) {
        this.$showSnackbar({
          preset: "error",
          text: `&Egrave; necessario fornire almeno la data di inizio per l'evento!`,
          actionText: "OK",
        });
        return;
      }
      if (title) {
        // set start and end on original event
        let eventDate = this.selectInfo.start;
        if (this.form[this.repository].fromDate) {
          fromDate = moment(
            this.form[this.repository].fromDate,
            "YYYY-MM-DD"
          ).toDate();
        }
        if (this.form[this.repository].toDate) {
          toDate = moment(
            this.form[this.repository].toDate,
            "YYYY-MM-DD"
          ).toDate();
        }
        let startDate = eventDate;
        let endDate = eventDate;
        if (fromDate) {
          startDate = fromDate;
        }
        if (toDate) {
          endDate = toDate;
        }
        if (startDate || endDate) {
          if (this.form[this.repository].task_type === 1) {
            /* Fix attività */
            endDate = fromDate;
            this.form[this.repository].start = moment(
              this.form[this.repository].startDate
            ).format("YYYY-MM-DD");
            this.form[this.repository].end = moment(
              this.form[this.repository].startDate
            ).format("YYYY-MM-DD");
          } else {
            /* Appuntamenti */
            this.setSelectedEventDates(startDate, endDate);
          }
        } else {
          this.form[this.repository].start = this.form[this.repository].end =
            null;
        }
        let body = this.prepareBody(
          startDate,
          endDate,
          this.form[this.repository]
        );
        /* Ricorrenze */
        if (
          Object.prototype.hasOwnProperty.call(body, "started_at") &&
          body.started_at &&
          Object.prototype.hasOwnProperty.call(body, "recurrence_type") &&
          Object.prototype.hasOwnProperty.call(body, "frequencyEnd") &&
          body.frequencyEnd === "after" &&
          Object.prototype.hasOwnProperty.call(body, "toDateFrequency")
        ) {
          let n = body.toDateFrequency;
          let finalFrequencyDate = n;
          switch (body.recurrence_type) {
            case 0:
              // Giornaliera
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "d")
                .format("YYYY-MM-DD");
              break;
            case 7:
              // Settimanale
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "w")
                .format("YYYY-MM-DD");
              break;
            case 1:
              // Mensile
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "M")
                .format("YYYY-MM-DD");
              break;
            case 2:
              // Bimestrale
              n = n * 2;
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "M")
                .format("YYYY-MM-DD");
              break;
            case 3:
              // Trimestrale
              n = n * 3;
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "M")
                .format("YYYY-MM-DD");
              break;
            case 4:
              // Quadrimestrale
              n = n * 4;
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "M")
                .format("YYYY-MM-DD");
              break;
            case 6:
              // Semestrale
              n = n * 6;
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "M")
                .format("YYYY-MM-DD");
              break;
            case 12:
              // Annuale
              finalFrequencyDate = moment(this.form[this.repository].fromDate)
                .add(n, "y")
                .format("YYYY-MM-DD");
              break;
            default:
              console.log("No date_frequency matched!");
              break;
          }
          body.recurrence_end_date = finalFrequencyDate;
        }
        /* Ricorrenze */
        /* Promemoria / Memo */
        if (
          Object.prototype.hasOwnProperty.call(body, "started_at") &&
          body.started_at &&
          Object.prototype.hasOwnProperty.call(body, "memo") &&
          body.memo === "Y" &&
          Object.prototype.hasOwnProperty.call(body, "memo_days") &&
          body.memo_days
        ) {
          const memoStartDate = moment(this.form[this.repository].fromDate)
            .subtract(body.memo_days, "d")
            .format("YYYY-MM-DD");
          body.memo_start_date = memoStartDate;
          body.memo_end_date = moment(
            this.form[this.repository].fromDate
          ).format("YYYY-MM-DD");
        }
        /* Promemoria / Memo */
        /* Ereditarietà Note */
        if (this.form[this.repository].inheritance != null) {
          body.inheritance = this.form[this.repository].inheritance;
        }
        /* Ereditarietà Note */
        const isTaskable = !!this.taskable_id && !!this.taskable;
        const Repo = RepositoryFactory.get(this.repository);
        Repo.store(body)
          .then((response) => {
            this.closeCreationDialog();
            id = response.data.data.id;
            // DEDICATA al tab NOTE del Quietanzamento
            if (this.TASK_IDS) {
              this.TASK_IDS.push(id);
            }
            if (isTaskable) {
              let payload = {
                [this.taskable]: {
                  [this.taskable_id]: {},
                },
              };
              Repo.pivot_store(id, this.taskable, payload)
                .then(() => {
                  this.$showSnackbar({
                    preset: "success",
                    text: `Task creato con successo`,
                  });
                  // emit fetch, if any handler is listening to...
                  this.$emit("fetch");
                  let criteria = this.filter;
                  let name = this.filterName;
                  this.saveFilterByName({ name, criteria });
                  this.$refs[this.tableRef]
                    .fetch()
                    .then(() => {
                      this.removeFilterByName(name);
                      // Se è una nota e il flag 'crea appuntamento' è attivo
                      if (this.task_type === 2 && this.create_appointment) {
                        this.openAppointmentModal(0, response.data.data);
                      }
                    })
                    .catch((error) => {
                      let errMsg = this.$getErrorMessage(error);
                      this.$showSnackbar({ preset: "error", text: errMsg });
                    });
                })
                .catch((error) => {
                  console.error(error);
                  throw error;
                });
            } else {
              this.fetchEvents(this.fromCalendar, !this.fromCalendar);
            }
            // NOTE: non c'è più il "giochino" di tramutare todo in eventi del calendario
            // if (!this.fromCalendar) {
            //   if (startDate || endDate) {
            //     this.fromCalendar = true;
            //   }
            // }
          })
          .catch((error) => {
            this.closeCreationDialog();
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "error",
          text: `&Egrave; necessario fornire almeno il titolo.`,
          actionText: "OK",
        });
      }
    },
    openAppointmentModal(type, data) {
      // apri modale di creazione appuntamento
      this.$bvModal.hide("eventCreateModal");
      this.createTask(type);
      this.form[this.repository].title = data.title;
      this.form[this.repository].description = data.description;
      this.create_appointment = false;
    },
    // refactored
    putEvent(selInf) {
      // update event on calendar
      if (!selInf) {
        selInf = this.selectInfo;
      }

      let id = selInf.id;
      let title = this.form[this.repository].title;
      let fromDate;
      let toDate;

      // se all day ma la start non c'è...
      if (
        this.form[this.repository].allDay &&
        !this.form[this.repository].fromDate
      ) {
        this.$showSnackbar({
          preset: "error",
          text: `&Egrave; necessario fornire almeno la data di inizio per l'evento!`,
          actionText: "OK",
        });
        return;
      }
      if (title) {
        let eventDate = this.form[this.repository].start;
        if (this.form[this.repository].fromDate) {
          fromDate = moment(
            this.form[this.repository].fromDate,
            "YYYY-MM-DD"
          ).toDate();
        }
        if (this.form[this.repository].toDate) {
          toDate = moment(
            this.form[this.repository].toDate,
            "YYYY-MM-DD"
          ).toDate();
        }
        let startDate = eventDate;
        let endDate = eventDate;
        if (fromDate) {
          startDate = fromDate;
        }
        if (toDate) {
          endDate = toDate;
        }
        if (startDate || endDate) {
          this.setSelectedEventDates(startDate, endDate);
        } else {
          this.form[this.repository].start = this.form[this.repository].end =
            null;
        }
        let body = this.prepareBody(
          startDate,
          endDate,
          this.form[this.repository]
        );
        /* Ereditarietà Note */
        if (this.form[this.repository].inheritance != null) {
          body.inheritance = this.form[this.repository].inheritance;
        }
        /* Ereditarietà Note */
        const isTaskable = !!this.taskable_id && !!this.taskable;
        const Repo = RepositoryFactory.get(this.repository);
        const qs = this.form.update_future_recurrence ? "upcoming=true" : null;
        Repo.update(id, body, qs)
          .then(() => {
            this.closeChangeDialog();
            this.$showSnackbar({
              preset: "success",
              text: `Task aggiornato con successo`,
            });
            // emit fetch, if any handler is listening to...
            this.$emit("fetch");
            // non c'è più il "giochino" di tramutare todo in eventi del calendario
            // if (!this.fromCalendar) {
            //   if (startDate || endDate) {
            //     this.fromCalendar = true;
            //     this.fetchEvents(!this.fromCalendar, this.fromCalendar);
            //   }
            // }
            if (isTaskable) {
              let criteria = this.filter;
              let name = this.filterName;
              this.saveFilterByName({ name, criteria });
              this.$refs[this.tableRef]
                .fetch()
                .then(() => {
                  this.removeFilterByName(name);
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({ preset: "error", text: errMsg });
                });
            } else {
              this.fetchEvents(this.fromCalendar, !this.fromCalendar);
            }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "error",
          text: `&Egrave; necessario fornire almeno il titolo.`,
          actionText: "OK",
        });
      }
    },
    deleteEvent(clickInfo, condition) {
      const type = `quest${clickInfo.task_type.value === 0 ? "o" : "a"} ${
        clickInfo.task_type.text
      }`;
      console.log(clickInfo);
      console.log("condition", condition);
      // backend does not really destroy records  (so far it's only a client side effect)
      let id;
      this.showConfirm({
        yesCallback: () => {
          if (this.fromCalendar) {
            id = clickInfo.id;
          } else {
            id = this.selectInfo.id;
          }
          const Repo = RepositoryFactory.get(this.repository);
          const qs = condition ? "upcoming=true" : null;
          Repo.destroy(id, qs)
            .then(() => {
              // REVIEW: now, if success, should fetch data again, in order to update the view
              // draw the calendar with fresh events, and re-draw the cards on the right
              // OR manage it client side
              if (this.fromCalendar) {
                // remove from calendar
                // clickInfo.remove();
                this.calendarOptions.events =
                  this.calendarOptions.events.filter(
                    (event) => event.id !== id
                  );
                this.tasks = this.tasks.filter((task) => task.id !== id);
              } /*  else {
                // remove from this.tasks
                console.log("TASKS", this.tasks);
                this.tasks = this.tasks.filter((task) => task.id !== id);
              } */
              this.$showSnackbar({
                preset: "success",
                text: `Eliminato con successo`,
                actionText: "OK",
              });
              this.$emit("fetch");
              this.fetchEvents(this.fromCalendar, !this.fromCalendar);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        },
        noCallback: null,
        title: "Conferma Eliminazione",
        message: `${
          condition
            ? `Vuoi davvero eliminare ${type} corrente e tutte le sue ricorrenze future?`
            : "Vuoi davvero eliminare?"
        }`,
        yesLabel: "ELIMINA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    setSelectedEventDates(startDate, endDate) {
      let allDay = this.form[this.repository].allDay ? true : false;
      let eventDate;
      let Y;
      let M;
      let D;
      if (startDate.getTime() === endDate.getTime() || !endDate) {
        eventDate = startDate;

        Y = eventDate.getFullYear();
        M = eventDate.getMonth();
        D = eventDate.getDate();
        // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);
        let startHour = "0";
        let startMinute = "0";
        let endHour = "23";
        let endMinute = "59";
        if (!allDay) {
          startHour =
            this.form[this.repository].startTime.split(":")[0] || "00";
          startMinute =
            this.form[this.repository].startTime.split(":")[1] || "00";
          endHour = this.form[this.repository].endTime.split(":")[0] || "00";
          endMinute = this.form[this.repository].endTime.split(":")[1] || "00";
        }
        this.form[this.repository].start = new Date(
          Y,
          M,
          D,
          startHour,
          startMinute,
          "00"
        );
        this.form[this.repository].end =
          endHour && endMinute
            ? new Date(Y, M, D, endHour, endMinute, "00")
            : "";
        // console.debug(
        //   "this.form[this.repository] start:" + this.form[this.repository].start
        // );
        // console.debug(
        //   "this.form[this.repository] end:" + this.form[this.repository].end
        // );
      } else {
        // startDate
        eventDate = startDate;

        Y = eventDate.getFullYear();
        M = eventDate.getMonth();
        D = eventDate.getDate();
        // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);
        let startHour = "0";
        let startMinute = "0";
        if (!allDay) {
          startHour =
            this.form[this.repository].startTime.split(":")[0] || "00";
          startMinute =
            this.form[this.repository].startTime.split(":")[1] || "00";
        }
        this.form[this.repository].start = new Date(
          Y,
          M,
          D,
          startHour,
          startMinute,
          "00"
        );
        // console.debug(
        //   "this.form[this.repository] start:" + this.form[this.repository].start
        // );

        // endDate
        eventDate = endDate;

        Y = eventDate.getFullYear();
        M = eventDate.getMonth();
        D = eventDate.getDate();
        // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);

        let endHour = "23";
        let endMinute = "59";
        let endSecond = "59";
        if (!allDay) {
          endHour = this.form[this.repository].endTime.split(":")[0] || "00";
          endMinute = this.form[this.repository].endTime.split(":")[1] || "00";
          endSecond = "00";
        }
        this.form[this.repository].end =
          endHour && endMinute
            ? new Date(Y, M, D, endHour, endMinute, endSecond)
            : "";
        // console.debug(
        //   "this.form[this.repository] end:" + this.form[this.repository].end
        // );
      }
    },
    prepareBody(startDate, endDate, selectedEvent) {
      let task_type =
        selectedEvent.task_type != null &&
        typeof selectedEvent.task_type === "object"
          ? selectedEvent.task_type.value
          : selectedEvent.task_type;
      let allDay = selectedEvent.allDay ? true : false;
      let title = selectedEvent.title;
      let description = selectedEvent.description;
      let recurrence_type = selectedEvent.recurrence_type;
      let frequencyEnd = selectedEvent.frequencyEnd;
      let toDateFrequency = selectedEvent.toDateFrequency;
      let recurrence_end_date = selectedEvent.recurrence_end_date;
      let memo = selectedEvent.memo;
      let memo_days = selectedEvent.memo_days;
      // let users = selectedEvent.users;
      let users = {};
      if (selectedEvent.users) {
        selectedEvent.users.forEach((item) => {
          users[item.id || item] = {};
        });
      }
      let status_task =
        selectedEvent.status_task != null &&
        typeof selectedEvent.status_task === "object"
          ? selectedEvent.status_task.value
          : selectedEvent.status_task;
      let expired_at = selectedEvent.expired_at;
      // let owner_id = selectedEvent.owner_id;
      let is_public =
        selectedEvent.is_public != null &&
        typeof selectedEvent.is_public === "object"
          ? selectedEvent.is_public.value
          : selectedEvent.is_public;
      // let taskable_id = this.taskable_id;
      // let taskable = this.taskable;
      let start;
      let end;
      let eventDate;
      let Y;
      let M;
      let D;
      if (startDate || endDate) {
        if (startDate.getTime() === endDate.getTime() || !endDate) {
          // inizio e fine uguali o fine non settato: 1 giorno
          eventDate = startDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);
          let startHour = "0";
          let startMinute = "0";
          let endHour = "23";
          let endMinute = "59";
          let endSeconds = allDay ? "59" : "00";

          if (!allDay) {
            startHour = selectedEvent.startTime.split(":")[0] || "00";
            startMinute = selectedEvent.startTime.split(":")[1] || "00";
            endHour = selectedEvent.endTime.split(":")[0] || "00";
            endMinute = selectedEvent.endTime.split(":")[1] || "00";
          }

          start = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${startHour.padStart(2, "0")}:${startMinute.padStart(2, "0")}:00`;
          end = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${endHour.padStart(2, "0")}:${endMinute.padStart(
            2,
            "0"
          )}:${endSeconds}`;
        } else {
          // inizio e fine diversi, più giorni
          // startDate
          eventDate = startDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);
          let startHour = "0";
          let startMinute = "0";
          if (!allDay) {
            startHour = selectedEvent.startTime.split(":")[0] || "00";
            startMinute = selectedEvent.startTime.split(":")[1] || "00";
          }

          start = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${startHour.padStart(2, "0")}:${startMinute.padStart(2, "0")}:00`;

          // endDate
          eventDate = endDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          // console.debug(`Year: ${Y}, Month: ${M}, Day: ${D}`);

          let endHour = "23";
          let endMinute = "59";
          let endSeconds = allDay ? "59" : "00";
          if (!allDay) {
            endHour = selectedEvent.endTime.split(":")[0] || "00";
            endMinute = selectedEvent.endTime.split(":")[1] || "00";
          }

          end = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${endHour.padStart(2, "0")}:${endMinute.padStart(
            2,
            "0"
          )}:${endSeconds}`;
        }
      }

      // TODO: check taskable !!!
      let body = {
        start,
        end,
        // allDay,
        title,
        description,
        // users,
        user: users,
        task_type,
        status_task,
        expired_at,
        is_public,
        // owner_id,
        //taskable_id,
        //taskable,
        recurrence_type,
        frequencyEnd,
        toDateFrequency,
        recurrence_end_date,
        memo,
        memo_days,
      };
      // if (this.fromCalendar) {
      //   body.task_type = 0;
      // } else if (!this.fromCalendar && this.taskType !== 2) {
      //   if (startDate || endDate) {
      //     body.task_type = 0;
      //   } else {
      //     body.task_type = 1;
      //     body.status_task = 0;
      //   }
      // }
      // console.debug("taskable: ", taskable);
      // console.debug("taskable_id: ", taskable_id);
      body = this.convertCalendarEventToData(body);
      return body;
    },
    ...mapGetters("auth", ["user"]),
  },
  computed: {
    // typ: {
    //   get() {
    //     return parseInt(this.taskType);
    //   },
    // },
    inf: {
      get() {
        return this.selectInfo;
      },
    },
    evt: {
      get() {
        // return this.selectedEvent;
        return this.form[this.repository];
      },
    },
    frmC: {
      get() {
        return this.formLoaded;
      },
    },
    frmE: {
      get() {
        return this.formEditLoaded;
      },
    },
    frmS: {
      get() {
        return this.formLoaded;
      },
    },
    clndr: {
      get() {
        return this.fromCalendar;
      },
    },
  },
};
