// keep things alphabelically sorted !!!
const Dictionary = Object.freeze({
  // create/edit forms
  it: {
    resources: {
      address_book: {
        status_addressbook: {
          label: "Tipo di Recapito",
        },
        attribute_MAIL_value: {
          label: "E-Mail",
        },
        title: {
          label: "Descrizione",
        },
        attribute_PEC_value: {
          label: "PEC",
        },
        attribute_WEB_value: {
          label: "Sito internet",
        },
        attribute_PHON_value: {
          label: "Telefono",
        },
        attribute_CELL_value: {
          label: "Cellulare",
        },
        attribute_FAX_value: {
          label: "Fax",
        },
        attribute_SMSFROM_value: {
          label: "Mittente SMS",
        },
        attribute_SIGNMAIL_value: {
          label: "Mittente MAIL",
        },
      },
      advance_payment: {
        status: {
          label: "Stato Acconto",
        },
        total_deposit: {
          label: "Importo Acconto",
        },
        negative_deposit: {
          label: "Importo Recuperato",
        },
        diff_deposit: {
          label: "Importo Da Recuperare",
        },
      },
      agency_book: {
        opening_balance: {
          label: "Apertura Saldo",
        },
        book_date: {
          label: "Data Registrazione",
        },
        broker: {
          label: "Produttore associato",
        },
        effective_date: {
          label: "Data Effetto",
        },
        additional_description: {
          label: "Descrizione",
        },
        gross: {
          label: "Lordo",
        },
        invoice_protocol_number: {
          label: "Nr. protocollo fatture passive",
        },
        agencybook_type: {
          label: "Tipologia Movimento",
        },
        treasury_id: {
          label: "Cassa",
        },
        payment_reference_id: {
          label: "Causale",
        },
        payment_reference_code: {
          label: "Causale Movimento",
        },
      },
      agency_contact: {
        pk: {
          label: "Identificativo primario",
        },
        sk: {
          label: "Identificativo secondario",
        },
        status_agencycontact: {
          label: "Tipo Contatto",
        },
        attribute_TITL_value: {
          label: "Titolo",
        },
        title: {
          label: "Descrizione",
        },
        city: {
          label: "Città",
        },
        province: {
          label: "Provincia",
        },
        phone: {
          label: "Telefono",
        },
        email: {
          label: "Email",
        },
        attribute_SEX_value: {
          label: "Sesso",
        },
        attribute_NAME_value: {
          label: "Nome",
        },
        attribute_SURN_value: {
          label: "Cognome",
        },
        attribute_CNAM_value: {
          label: "Ragione sociale",
        },
        attribute_NINO_value: {
          label: "Codice Fiscale",
        },
        attribute_CREG_value: {
          label: "Partita IVA",
        },
        attribute_MARS_value: {
          label: "Stato civile",
        },
        attribute_PROF_value: {
          label: "Professione",
        },
        attribute_DOB_value: {
          label: "Data nascita",
        },
        attribute_POB_value: {
          label: "Luogo nascita",
        },
        attribute_POB2_value: {
          label: "Luogo nascita estero",
        },
        attribute_NOB_value: {
          label: "Nazione nascita",
        },
        attribute_NOB2_value: {
          label: "Nazione di nascita estera",
        },
        attribute_TREG_value: {
          label: "Identificativo temporaneo",
        },
        attribute_CLI_value: {
          label: "Cliente",
        },
        attribute_SUP_value: {
          label: "Fornitore",
        },
      },
      analysis: {
        name: {
          label: "Titolo",
        },
        description: {
          label: "Descrizione",
        },
        download: {
          label: "Download",
        },
        created_at: {
          label: "Creato il",
        },
        updated_at: {
          label: "Ultimo aggiornamento",
        },
      },
      attribute: {
        is_hidden: {
          label: "Visibilità",
        },
        text: {
          label: "Nome Campo",
        },
        is_searchable: {
          label: "Ricercabile",
        },
        is_sortable: {
          label: "Ordinabile",
        },
        type: {
          label: "Tipo",
        },
        section: {
          label: "Sezione",
        },
        entity: {
          label: "Entità Associata",
        },
      },
      agency_setup: {
        code: {
          label: "Codice Agenzia",
        },
        title: {
          label: "Descrizione Agenzia",
        },
        attribute_ROBR_value: {
          label: "Iscrizione RUI",
        },
        attribute_NINO_value: {
          label: "Codice Fiscale",
        },
        attribute_CREG_value: {
          label: "Partita Iva",
        },
        attribute_CNAM_value: {
          label: "Ragione Sociale",
        },
      },
      bank_account: {
        abi: {
          label: "ABI",
        },
        bank: {
          label: "Banca",
        },
        cab: {
          label: "CAB",
        },
        code: {
          label: "Code",
        },
        cuc: {
          label: "Codice Univoco CBI",
        },
        credit_account: {
          label: "Conto di accredito",
        },
        debtor_iban: {
          label: "Codice IBAN debitore",
        },
        iban: {
          label: "IBAN",
        },
        identifier: {
          label: "Creditor Identifier",
        },
        referer_type: {
          label: "Refer Type",
        },
        status_setup: {
          label: "Disabilitazione",
        },
        support: {
          label: "Nome Supporto",
        },
        title: {
          label: "Title",
        },
        use_rid: {
          label: "Utilizzo flusso RID",
        },
      },
      book_tag: {
        title: {
          label: "Descrizione",
        },
        code: {
          label: "Codice Documento",
        },
        is_system: {
          label: "Sistema",
        },
      },
      book_entry: {
        title: {
          label: "Descrizione",
        },
        registry: {
          label: "Contraente",
        },
        insurance_policy_number: {
          label: "Numero polizza",
        },
        book_date: {
          label: "Data Registrazione",
        },
        effective_date: {
          label: "Data Effetto",
        },
        sheet_date: {
          label: "Data Foglio Cassa",
        },
        net: {
          label: "Imponibile",
        },
        various_accountings: {
          label: "Causale",
        },
        tax: {
          label: "Imposte",
        },
        gross: {
          label: "Lordo",
        },
        fee_gross: {
          label: "Diritti",
        },
        fee_net: {
          label: "Netto",
        },
        fee_tax: {
          label: "Tasse",
        },
        take: {
          label: "Incasso Diretto",
        },
        insurer_id: {
          label: "Compagnia",
        },
        insurance_risk: {
          label: "Codice Rischio Compagnia",
        },
        risk_branch: {
          label: "Codice Ramo Compagnia",
        },
        insurer_mandate_code: {
          label: "Codice mandato compagnia",
        },
        agency_prov_purchase: {
          label: "Provvigioni d'agenzia d'acquisto",
        },
        agency_prov_take: {
          label: "Provvigioni d'agenzia d'incasso",
        },
        saler_prov_purchase: {
          label: "Provvigioni del produttore d'acquisto",
        },
        saler_prov_take: {
          label: "Provvigioni del produttore d'incasso",
        },
        saler_fee_take: {
          label: "Diritti di incasso del produttore",
        },
        total_prov: {
          label: "Totale provvigioni",
        },
        imported_at: {
          label: "Data di Importazione",
        },
        is_taxable: {
          label: "Soggetto a Ritenuta",
        },
        broker: {
          label: "Cod. Descr. Produttore",
        },
        entry_in: {
          label: "Passivi",
        },
        entry_out: {
          label: "Attivi",
        },
        entry_in_company: {
          label: "Uscite",
        },
        entry_out_company: {
          label: "Entrate",
        },
        neg_rebate: {
          label: "Attivi",
        },
        pos_rebate: {
          label: "Passivi",
        },
      },
      breakdown: {
        title: {
          label: "Scorporo",
        },
        insurance_risk_id: {
          label: "Prodotto assicurativo",
        },
        insurer_id: {
          label: "Compagnia assicurativa",
        },
        is_net: {
          label: "È netto",
        },
        is_tax: {
          label: "È tasse",
        },
      },
      broker: {
        pk: {
          label: "Identificativo primario",
        },
        sk: {
          label: "Identificativo secondario",
        },
        status_broker: {
          label: "Tipologia",
        },
        read: {
          label: "Lettura",
        },
        write: {
          label: "Modifica",
        },
        cooperator: {
          label: "Denominazione Collaborazione",
        },
        attribute_TITL_value: {
          label: "Titolo",
        },
        title: "Status Salesman",
        attribute_SEX_value: {
          label: "Sesso",
        },
        attribute_NAME_value: {
          label: "Nome",
        },
        attribute_SURN_value: {
          label: "Cognome",
        },
        attribute_CNAM_value: {
          label: "Ragione sociale",
        },
        attribute_NINO_value: {
          label: "Codice Fiscale",
        },
        attribute_CREG_value: {
          label: "Partita IVA",
        },
        attribute_MARS_value: {
          label: "Stato civile",
        },
        attribute_PROF_value: {
          label: "Professione",
        },
        attribute_DOB_value: {
          label: "Data nascita",
        },
        attribute_POB_value: {
          label: "Luogo nascita",
        },
        attribute_POB2_value: {
          label: "Luogo nascita estero",
        },
        attribute_NOB_value: {
          label: "Nazione nascita",
        },
        attribute_NOB2_value: {
          label: "Nazione di nascita estera",
        },
        attribute_TREG_value: {
          label: "Identificativo temporaneo",
        },
        attribute_BUID_value: {
          label: "Codice univoco Lisaweb",
        },
        attribute_BAHO_value: {
          label: "Intestazione Conto Corrente",
        },
        attribute_BANK_value: {
          label: "Banca",
        },
        attribute_BABR_value: {
          label: "Filiale",
        },
        attribute_IBAN_value: {
          label: "IBAN",
        },
        attribute_ROBR_value: {
          label: "Iscrizione Rui",
        },
        attribute_WHT_value: {
          label: "% Ritenuta d'acconto",
        },
        attribute_FCPR_value: {
          label: "% Provvigione Fissa",
        },
        attribute_INNO_value: {
          label: "Nota su Fattura",
        },
        attribute_RENO_value: {
          label: "Nota su Rendiconto provvigionale",
        },
        attribute_FINO_value: {
          label: "Nota su Quietanze",
        },
        attribute_TKTP_value: {
          label: "Tipo Incasso",
        },
        attribute_REDE_value: {
          label: "Avvisi di scadenza: includi Sospesi",
        },
        attribute_SEEX_value: {
          label: "Esclusione Invio Scadenze",
        },
        attribute_REPE_value: {
          label: "Avvisi di scadenza: includi Arretrati",
        },
        attribute_READ_value: {
          label: "Avvisi di scadenza: includi Acconti",
        },
        attribute_STSA_value: {
          label: "Status Salesman",
        },
        attribute_address: {
          label: "Indirizzo",
        },
        attribute_RUI_value: {
          label: "Nr. RUI",
        },
        attribute_ritenuta_value: {
          label: "% ritenuta d'acconto",
        },
        attribute_TYPBROK_value: {
          label: "Broker",
        },
      },
      broker_statement: {
        from: {
          label: "Data dal",
        },
        to: {
          label: "Data al",
        },
        invoice_number: {
          label: "Numero polizza",
        },
        invoice_period: {
          label: "Periodo polizza",
        },
        broker_id: {
          label: "Broker",
        },
      },
      cash_entry: {
        title: {
          label: "Descrizione",
        },
        cash_time: {
          label: "Data Registrazione",
        },
        description: {
          label: "Descrizione",
        },
        gross: {
          label: "Lordo",
        },
        cash_reference_id: {
          label: "Causale",
        },
        treasury_id: {
          label: "Conto Movimentato",
        },
        broker_id: {
          label: "Produttore",
        },
        cash_entry_id: {
          label: "Conto",
        },
        entry_in: {
          label: "Entrate",
        },
        entry_out: {
          label: "Uscite",
        },
      },
      cash_reference: {
        title: {
          label: "Descrizione",
        },
        code: {
          label: "Causale",
        },
        default_treasury: {
          label: "Conto di Default",
        },
        reference_type: {
          label: "Tipologia Movimento",
        },
        entry_type: {
          label: "Tipologia Conto",
        },
        has_broker: {
          label: "Include Produttore",
        },
        has_company: {
          label: "Include Compagnia",
        },
        cash_reference_id: {
          label: "Causale Relazionata",
        },
      },
      claim: {
        number: {
          label: "Numero Sinistro",
        },
        claim_type: {
          label: "Tipo sinistro",
        },
        referring_at: {
          label: "Anno",
        },
        place: {
          label: "Luogo accadimento",
        },
        description: {
          label: "Descrizione accadimento",
        },
        risk_branch: {
          label: "Ramo Polizza",
        },
        occurred_at: {
          label: "Data Accadimento",
        },
        dcpd_module_type: {
          label: "CID",
        },
        driver: {
          label: "Conducente",
        },
        counterpart: {
          label: "Controparte",
        },
        insurance_policy_id: {
          label: "Polizza",
        },
        inspector: {
          label: "Ispettorato",
        },
        settled_total: {
          label: "Totale liquidato",
        },
        settlement_expense: {
          label: "Costo liquidazione sinistri",
        },
        partially_settled_at: {
          label: "Data ultima liquidazione",
        },
        deductible: {
          label: "Franchigia",
        },
        amount_in_reserve: {
          label: "Ammontare in riserva",
        },
        surveyor: {
          label: "Perito",
        },
        dcpd_module_filled: {
          label: "Compilazione cid ?",
        },
        is_covered: {
          label: "Copertura Regolare",
        },
      },
      claim_party: {
        pk: {
          label: "Identificativo primario",
        },
        sk: {
          label: "Identificativo secondario",
        },
        status_claimparty: {
          label: "Tipo di Controparte",
        },
        attribute_SEX_value: {
          label: "Sesso",
        },
        attribute_FULN_value: {
          label: "Nome Completo",
        },
        attribute_NINO_value: {
          label: "Codice Fiscale",
        },
        attribute_CREG_value: {
          label: "Partita IVA",
        },
        attribute_MARS_value: {
          label: "Stato civile",
        },
        attribute_PROF_value: {
          label: "Professione",
        },
        attribute_DOB_value: {
          label: "Data nascita",
        },
        attribute_POB_value: {
          label: "Luogo nascita",
        },
        attribute_POB2_value: {
          label: "Luogo nascita estero",
        },
        attribute_NOB_value: {
          label: "Nazione nascita",
        },
        attribute_NOB2_value: {
          label: "Nazione di nascita estera",
        },
        attribute_LEGP_value: {
          label: "Persona Giuridica",
        },
        attribute_PVOB_value: {
          label: "Provincia nascita",
        },
        attribute_PROV_value: {
          label: "Provincia",
        },
      },
      commission: {
        purchase_calculation_type: {
          label: "Calcolo Acquisto",
        },
        purchase_commission: {
          label: "Provvigione",
        },
        take_calculation_type: {
          label: "Calcolo Incasso",
        },
        take_commission: {
          label: "Provvigione",
        },
      },
      consolidated_treasury: {
        insurer_id: {
          label: "Compagna",
        },
        treasury_id: {
          label: "Cassa",
        },
        gross: {
          label: "Lordo",
        },
        consolidated_at: {
          label: "Data Consolidamento",
        },
      },
      correspondence: {
        status_correspondence: {
          label: "Tipo di indirizzo",
        },
        attribute_ADDR_value: {
          label: "Indirizzo",
        },
        title: {
          label: "Descrizione",
        },
        attribute_CITY_value: {
          label: "Comune",
        },
        attribute_NAME2_value: {
          label: "Nome/Rag.soc. alternativo",
        },
        attribute_CITY2_value: {
          label: "Città estera",
        },
        attribute_ZIPC_value: {
          label: "CAP",
        },
        attribute_NOR_value: {
          label: "Nazione di residenza",
        },
        attribute_NOR2_value: {
          label: "Nazione di residenza estera",
        },
        city: {
          label: "Città",
        },
        domicilio: {
          label: "Indirizzo",
        },
      },
      counterpart: {
        attribute_FULN: {
          label: "Nominativo",
        },
        attribute_CPRGP: {
          label: "targa controparte",
        },
        attribute_CPINS: {
          label: "compagnia controparte",
        },
        attribute_CPVEH: {
          label: "veicolo controparte",
        },
      },
      direct_debit: {
        setup_at: {
          label: "Data istanziamento SDD",
        },
        bank_account_id: {
          label: "Banca",
        },
        download_xml: {
          label: "Download",
        },
        id: {
          label: "ID Flusso",
        },
        insurer_id: {
          label: "Compagnia assicuratrice",
        },
        payment_procedure_count: {
          label: "Numero quietanze nel flusso",
        },
      },
      document: {
        title: {
          label: "Nome Documento",
        },
        note: {
          label: "Descrizione documento",
        },
        document_type: {
          label: "Tipo Documento",
        },
        source_type: {
          label: "Sorgente Documento",
        },
        metadata: {
          label: "Metadati",
        },
        category: {
          label: "Categoria",
        },
      },
      entry_detail: {
        book_entry_id: {
          label: "Mov. Cont.",
        },
        treasury_id: {
          label: "Cassa",
        },
        gross: {
          label: "Lordo",
        },
        detail_date: {
          label: "Data",
        },
      },
      fattutto: {
        active: {
          label: "Abilitato",
        },
        consolidated_at: {
          label: "Data Consolidata",
        },
        entity: {
          label: "Entità",
        },
        entity_code: {
          label: "Codice - Titolo Entità",
        },
        fattutto_transmission_id: {
          label: "ID",
        },
        ledger_code: {
          label: "Codice Partitario",
        },
        ledger_type: {
          label: "Partitario",
        },
        negative_code: {
          label: "Codice flusso negativo",
        },
        transmission_status: {
          label: "Stato",
        },
        code: {
          label: "Codice",
        },
        created_at: {
          label: "Creato il",
        },
        updated_at: {
          label: "Ultimo aggiornamento",
        },
        progress_percentage: {
          label: "% avanzamento",
        },
        records: {
          label: "Record inviati / Record in errore / Record totali",
        },
        errors: {
          label: "Errori",
        },
      },
      form: {
        multiselect_no_options: {
          label: "Nessuna opzione disponibile",
        },
        multiselect_no_results: {
          label: "Nessuna opzione trovata per il testo ricercato",
        },
        multiselect_selected: {
          label: "Selezionato",
        },
      },
      generated_report: {
        code: {
          label: "Codice Report",
        },
        formatted_title: {
          label: "Titolo Report",
        },
        format: {
          label: "Formato",
        },
        status_report: {
          label: "Stato Report",
        },
        is_ready: {
          label: "Pronto",
        },
        download: {
          label: "Scarica Documenti",
        },
      },
      import_detail: {
        code: {
          label: "Codice",
        },
        error: {
          label: "Errore",
        },
        is_error: {
          label: "Rifiutato",
        },
        title: {
          label: "Gruppo",
        },
      },
      import_log: {
        details: {
          label: "Dettagli",
        },
        failed: {
          label: "In Errore",
        },
        group: {
          label: "Gruppo",
        },
        last_update: {
          label: "Data Ultima Modifica",
        },
        parsed: {
          label: "Analizzato",
        },
        status_import_log: {
          label: "Stato Importazione",
        },
        status_broker_statement_log: {
          label: "Stato Invio",
        },
        total: {
          label: "Totale",
        },
      },
      import_map: {
        map: {
          label: "Mappatura",
        },
        insurer_id: {
          label: "Compagnia",
        },
      },
      insurance_payment: {
        gross_premium: {
          label: "Premio",
        },
        number: {
          label: "Polizza di riferimento",
        },
        comment: {
          label: "Commento",
        },
        status_payment: {
          label: "Stato",
        },
        payment_type: {
          label: "Tipo",
        },
        referring_at: {
          label: "Data Scadenza",
        },
        gross: {
          label: "Premio",
        },
        status_policy: {
          label: "Stato polizza",
        },
        cooperator: {
          label: "Collaborazione",
        },
      },
      insurance_policy: {
        title: {
          label: "Denominazione Polizza",
        },
        number: {
          label: "Numero Polizza",
        },
        mandate_code: {
          label: "Codice Mandato",
        },
        insurer_id: {
          label: "Azienda",
        },
        status_policy: {
          label: "Stato",
        },
        old_policy: {
          label: "Polizza sostituita",
        },
        new_policies: {
          label: "Polizze sostituenti",
        },
        covered_at: {
          label: "Data Copertura",
        },
        effective_at: {
          label: "Data Decorrenza",
        },
        suspended_at: {
          label: "Data Sospensione",
        },
        expired_at: {
          label: "Data Scadenza",
        },
        gross_premium: {
          label: "Lordo Rata",
        },
        total_gross: {
          label: "Lordo Annuo",
        },
        gross_payment_splitting: {
          label: "Lordo Annuo",
        },
        net_premium: {
          label: "Premio netto",
        },
        tax_premium: {
          label: "Imposte",
        },
        payment_splitting: {
          label: "Fraz.",
        },
        attribute_BRAN_value: {
          label: "Marca",
        },
        attribute_CANIA_value: {
          label: "Codice Classe ANIA",
        },
        attribute_KWHP_value: {
          label: "Kilowatt potenza",
        },
        attribute_LESC_value: {
          label: "Settore Legislativo",
        },
        attribute_MODL_value: {
          label: "Modello",
        },
        attribute_NCLBC_value: {
          label: "Classe di merito compagnia",
        },
        attribute_NCLB_value: {
          label: "Classe di merito",
        },
        attribute_OVALU_value: {
          label: "Valore Optional",
        },
        attribute_POW_value: {
          label: "Tipo Alimentazione",
        },
        attribute_QMTR_value: {
          label: "Quintali Motrice Autocarri",
        },
        attribute_QTTR_value: {
          label: "Quintali Rimorchio Autocarri",
        },
        attribute_REGM_value: {
          label: "Mese registrazione",
        },
        attribute_REGY_value: {
          label: "Anno registrazione",
        },
        attribute_REPL_value: {
          label: "Targa",
        },
        attribute_SANIA_value: {
          label: "Codice Settore ANIA",
        },
        attribute_SETP_value: {
          label: "Allestimento",
        },
        attribute_STNO_value: {
          label: "Numero posti",
        },
        attribute_STTL_value: {
          label: "Numero posti complessivi",
        },
        attribute_STTR_value: {
          label: "Numero posti rimorchio",
        },
        attribute_TXHP_value: {
          label: "Cavalli Fiscali",
        },
        attribute_UANIA_value: {
          label: "Codice Uso ANIA",
        },
        attribute_VALU_value: {
          label: "Valore Commerciale",
        },
        attribute_VEHT_value: {
          label: "Tipologia veicolo",
        },
        attribute_WEIG_value: {
          label: "Peso complessivo",
        },
        attribute_REVD_value: {
          label: "Data storno o rescindibilità",
        },
        attribute_RENW_value: {
          label: "Tacito Rinnovo",
        },
        attribute_INDX_value: {
          label: "Indicizzata",
        },
        attribute_CONV_value: {
          label: "Convenzione",
        },
        attribute_LNOT_value: {
          label: "Disdettata",
        },
        attribute_BONA_value: {
          label: "Regolazione Premio",
        },
        delay_days: {
          label: "Giorni di mora",
        },
      },
      insurance_ancillary: {
        ancillary_code: {
          label: "Quietanza",
        },
        title: {
          label: "Descrizione",
        },
        code: {
          label: "Tipo titolo",
        },
        effective_at: {
          label: "Data Effetto",
        },
        insurance_policy_id: {
          label: "Polizza",
        },
        cooperators: {
          label: "Polizza in collaborazione",
        },
        broker_id: {
          label: "Produttore",
        },
        net: {
          label: "Netto",
        },
        tax: {
          label: "Tasse",
        },
        gross: {
          label: "Lordo",
        },
        fee_gross: {
          label: "Di cui Diritti",
        },
        delay_days: {
          label: "Giorni di mora",
        },
        delay_until: {
          label: "Ultimo Giorno",
        },
        status_ancillary: {
          label: "Stato Titolo",
        },
      },
      insurance_risk: {
        title: {
          label: "Rischio / Prodotto",
        },
        code: {
          label: "Codice Rischio",
        },
        custom_code: {
          label: "Codice",
        },
        insurer_id: {
          label: "Compagnia",
        },
        risk_branch_id: {
          label: "Ramo",
        },
        risk_type: {
          label: "Codice Rischio",
        },
        delay_days: {
          label: "Giorni di mora",
        },
      },
      insurer: {
        title: {
          label: "Descrizione Compagnia",
        },
        code: {
          label: "Codice Produttore di Compagnia",
        },
        payment: {
          label: "Codice forma di pagamento di Compagnia",
        },
        importer_id: {
          label: "Importatore",
        },
      },
      job_spool: {
        status_jobspool: {
          label: "Stato",
        },
        last_update: {
          label: "Data Creazione",
        },
      },
      insurer_participant: {
        title: {
          label: "Ragione Sociale Intermediario Coass",
        },
        code: {
          label: "Codice Agenzia",
        },
        title_importer: {
          label: "Compagnia Rappresentata",
        },
        code_importer: {
          label: "Codice Compagnia",
        },
      },
      mandate_code: {
        title: {
          label: "Descrizione Mandato",
        },
        code: {
          label: "Codice Mandato",
        },
        is_expired: {
          label: "Disabilitato",
        },
      },
      payment_reference: {
        title: {
          label: "Titolo",
        },
        code: {
          label: "Codice Causale",
        },
        caption: {
          label: "Descrizione",
        },
      },
      payment_procedure: {
        direct_debit_id: {
          label: "ID Flusso",
        },
        exclusion_type: {
          label: "Modalità di esclusione",
        },
        first_attempt: {
          label: "Primo invio",
        },
        gross: {
          label: "Lordo",
        },
        iban: {
          label: "Codice IBAN",
        },
        insurer: {
          label: "Compagnia",
        },
        identifier: {
          label: "Creditor identifier",
        },
        policy: {
          label: "Polizza",
        },
        processing_at: {
          label: "Data di esecuzione mandato",
        },
        risk: {
          label: "Prodotto",
        },
      },
      rebate: {
        title: {
          label: "Importo Abbuono",
        },
      },
      registry: {
        pk: {
          label: "Identificativo primario",
        },
        sk: {
          label: "Identificativo secondario",
        },
        insurer_id: {
          label: "Assicuratore",
        },
        status_registry: {
          label: "Tipo",
        },
        attribute_TITL_value: {
          label: "Titolo",
        },
        title: {
          label: "Descrizione",
        },
        attribute_SEX_value: {
          label: "Sesso",
        },
        attribute_NAME_value: {
          label: "Nome",
        },
        attribute_SURN_value: {
          label: "Cognome",
        },
        attribute_CNAM_value: {
          label: "Ragione sociale",
        },
        attribute_NINO_value: {
          label: "Codice Fiscale",
        },
        attribute_CREG_value: {
          label: "Partita IVA",
        },
        attribute_LIAB_value: {
          label: "Forma societaria",
        },
        attribute_MARS_value: {
          label: "Stato civile",
        },
        attribute_PROF_value: {
          label: "Professione/Attività",
        },
        attribute_DOB_value: {
          label: "Data nascita",
        },
        attribute_POB_value: {
          label: "Luogo nascita",
        },
        attribute_POB2_value: {
          label: "Luogo nascita estero",
        },
        attribute_NOB_value: {
          label: "Nazione nascita",
        },
        attribute_NOB2_value: {
          label: "Nazione di nascita estera",
        },
        attribute_TREG_value: {
          label: "Identificativo temporaneo",
        },
        attribute_CHAN_value: {
          label: "Canale preferito",
        },
        attribute_GDPR_value: {
          label: "Privacy",
        },
        attribute_DOCT_value: {
          label: "Tipo documento",
        },
        attribute_DOCN_value: {
          label: "N.ro documento",
        },
        attribute_DOCS_value: {
          label: "Data emissione",
        },
        attribute_DOCX_value: {
          label: "Scadenza",
        },
        attribute_ISPL_value: {
          label: "Località emissione",
        },
        relation: {
          label: "Relazioni",
        },
      },
      registry_group: {
        title: {
          label: "Descrizione",
        },
        is_coordinator: {
          label: "Capogruppo",
        },
        code: {
          label: "Codice",
        },
      },
      relation: {
        registry: {
          label: "Nome/Cognome/Rag.sociale",
        },
      },
      report: {
        code: {
          label: "Codice Report",
        },
        title: {
          label: "Descrizione",
        },
        pdf: {
          label: "PDF",
        },
        csv: {
          label: "CSV",
        },
      },
      risk_branch: {
        title: {
          label: "Ramo",
        },
        code: {
          label: "Codice Ramo",
        },
      },
      sector: {
        title: {
          label: "Comparto",
        },
        display: {
          label: "Codice",
        },
        is_hidden: {
          label: "Nascosto",
        },
        is_system: {
          label: "Sistema",
        },
        is_abstract: {
          label: "Astratto",
        },
        is_agency: {
          label: "Per Contabilità d'Agenzia",
        },
        initial_balance: {
          label: "Saldo Iniziale",
        },
        initial_balance_date: {
          label: "Data Inizio Saldo",
        },
        entry_tot: {
          label: "Saldo",
        },
      },
      task: {
        title: {
          label: "Titolo",
        },
        description: {
          label: "Descrizione",
        },
        task_type: {
          label: "Tipologia",
        },
        inheritance: {
          label: "Ereditarietà",
        },
        is_public: {
          label: "È Pubblico",
        },
        created_at: {
          label: "Data Creazione",
        },
        owner: {
          label: "Creato da",
        },
        started_at: {
          label: "Data inizio",
        },
        ended_at: {
          label: "Data fine",
        },
        expired_at: {
          label: "Data scadenza",
        },
        users: {
          label: "Condividi con",
        },
        taskable: {
          label: "Nome relazione",
        },
        taskable_id: {
          label: "ID relazione",
        },
        status_task: {
          label: "Stato",
        },
        polizza_sostituita: {
          label: "Polizza Sostituita",
        },
        polizza_sostituente: {
          label: "Polizza Sostituente",
        },
      },
      treasury: {
        title: {
          label: "Forma di pagamento Lisaweb",
        },
        code: {
          label: "Codice",
        },
        info: {
          label: "Informazione",
        },
        sector_id: {
          label: "Comparto",
        },
        status_treasury: {
          label: "Tipo",
        },
      },
      user: {
        username: {
          label: "Nome Utente",
        },
        email: {
          label: "Email",
        },
        role: {
          label: "Ruolo",
        },
        is_limited: {
          label: "Nascondi Provvigioni di Agenzia",
        },
        title: {
          label: "Descrizione",
        },
      },
      unsolved_payment: {
        title: {
          label: "Descrizione",
        },
        status_payment: {
          label: "Stato",
        },
        registry_id: {
          label: "Contraente",
        },
        amount: {
          label: "Ammontare",
        },
        unsolved_at: {
          label: "Data Decorrenza",
        },
      },
      various_accounting: {
        title: {
          label: "Descrizione",
        },
        description: {
          label: "Definizione",
        },
        code: {
          label: "Codice Causale",
        },
        is_anticipated: {
          label: "È anticipato",
        },
        is_system: {
          label: "È per sistema",
        },
        status_broker: {
          label: "Status su produttore",
        },
        status_registry: {
          label: "Status su anagrafica",
        },
        status_insurer: {
          label: "Status su compagnia",
        },
        value_type: {
          label: "Tipo Valore",
        },
      },
    },
    // index filters/tables
    address_book: "Contatti",
    advance_payment: "Acconto",
    advance_payments: "Acconti",
    agency_book: "Contabilità Agenzia",
    agency_contact: "Contatto Agenzia",
    agency_gross: "Provv. Agenzia",
    appointment: "Appuntamenti",
    balance_sheet: "Rendiconto",
    book_entry: "Foglio Cassa",
    breakdown: "Scorporo",
    broker_statement: "Rendiconto",
    broker_statement_id: "Rendiconto",
    broker_statement_included:
      "Rendicontato nel rendiconto n. §id§ (dal §date_from§ al §date_to§) emesso il §created_date§",
    broker_statement_not_included: "Non Rendicontato",
    broker: "Broker",
    claim_party: "Controparte",
    claim: "Sinistro",
    commission: "Provvigione",
    commissions: "Provvigioni",
    commissions_to_liquidate: "Provvigioni da liquidare",
    consolidated_treasury: "Consolidato",
    contractor: "Contraente",
    cooperator: "Collaborazione",
    cooperators: "Collaboratori",
    correspondence: "Indirizzo",
    country: "Nazione",
    country_ispl: "Nazione emissione",
    covered_at: "Data Copertura",
    created_at: "Data Creazione",
    customer_name: "Nominativo / Ragione Sociale",
    customer: "Cliente",
    deduction: "Ritenuta d'acconto",
    diritto_gross: "Diritti Lordo",
    diritto_net: "Diritti Netto",
    diritto_tax: "Tasse su Diritti",
    document: "Documenti",
    effective_at: "Data Decorrenza",
    entry_detail: "Dettaglio",
    expired_at: "Data Scadenza",
    fees: "Diritti",
    gross: "Lordo",
    gross_coass: "Pr. Lordo Coass",
    grossRow: "Lordo riga",
    import_map: "Mappa Import",
    insurance_ancillaries: "Titoli",
    insurance_ancillary: "Titolo",
    insurance_payment: "Quietanzamento",
    insurance_policies: "Polizze",
    insurance_policy: "Polizza",
    insurance_risk: "Prodotto",
    insurer_accounting: "Contabilità",
    insurer: "Compagnia",
    insurer_participant: "Compagnia Coass",
    invoice: "Fattura",
    invoice_number: "Numero fattura",
    issued_at: "Data Emissione ",
    loading: "Caricamento...",
    make_official_balance_sheet: "Ufficializza rendiconto",
    net: "Netto",
    note: "Note",
    of_which_deferred_to_collect: "Di cui sospesi da incassare",
    of_which_fees: "Di cui diritti",
    of_which_not_taxable: "Di cui non soggette a ritenuta d'acconto",
    of_which_still_available: "Di cui ancora disponibili",
    period: "Periodo",
    primary_group: "Gruppo primario",
    payment_reference: "Riferimento",
    premio_gross: "Premio Lordo",
    premio_gross_fk: "gross_fk",
    premio_net: "Premio Netto",
    premio_tax: "Tasse su Premio",
    premium: "Premio",
    premiums: "Premi",
    premiums_balance: "Saldo premi",
    province: "Provincia",
    province_ispl: "Provincia emissione",
    registry_group: "Gruppo",
    registry_groups: "Gruppi",
    registry: "Anagrafica",
    risk_branch: "Ramo-Rischio",
    salesman: "Produttore Lisaweb",
    salesmen: "Produttori",
    saler_gross: "Provv. Produttore",
    sector: "Comparto",
    sectors: "Comparti",
    summary: "Riepilogo",
    summary_balance_sheet: "Riepilogo Rendiconto",
    surveyor: "Perito",
    task: "Task",
    tax: "Tasse",
    todo: "Attività",
    total: "Totale",
    totals: "Totali",
    total_gross_premium: "Totale Premi",
    total_to_be_invoiced_gross: "Importo da incassare",
    treasury: "Cassa",
    various_accounting: "Movimento Vario",
  },
});

const skipWords = Object.freeze({
  it: [
    "il",
    "lo",
    "la",
    "i",
    "gli",
    "le",
    "un",
    "uno",
    "una",
    "del",
    "dello",
    "della",
    "dei",
    "degli",
    "delle",
    "di",
    "a",
    "da",
    "in",
    "con",
    "su",
    "per",
    "tra",
    "fra",
  ],
});

// TODO use vuex store dictionaries instead of freezed object above
// import store from "@/store";
// const Dictionary = store.state.dictionaries.dicts;

export const getDictionary = (
  field,
  repository,
  locale = "it",
  abbreviate = false,
  extraParams = {}
) => {
  let label = field;
  if (repository) {
    if (
      Dictionary[locale].resources[repository] &&
      Dictionary[locale].resources[repository][field]
    ) {
      label = abbreviate
        ? makeAbbr(
            removeArticles(
              Dictionary[locale].resources[repository][field].label,
              locale
            )
          )
        : Dictionary[locale].resources[repository][field].label;
    }
  } else {
    if (Dictionary[locale][field]) {
      label = abbreviate
        ? makeAbbr(removeArticles(Dictionary[locale][field], locale))
        : Dictionary[locale][field];
    }
  }
  if (extraParams && Object.keys(extraParams).length) {
    Object.keys(extraParams).forEach(
      (el) => (label = label.replace(`§${el}§`, extraParams[el]))
    );
  }
  return label;
};

/*
esempio:
str = "I diritti di incasso del produttore";
ret = makeAbbr(removeArticles(str));
> 'Dir.Inc.Pro'
*/

export const makeAbbr = (words, chars = 3, splitBy = " ", joinBy = ".") => {
  return words
    .split(splitBy)
    .map((word) => word[0].toUpperCase() + word.slice(1, chars))
    .join(joinBy);
};

export const removeArticles = (str, locale = "it") => {
  let keep = [];
  let words = str.split(" ");
  // if(words.length <= 1) return ret;
  for (let word of words) {
    if (!skipWords[locale].includes(word.toLowerCase())) {
      keep.push(word);
    }
  }
  return keep.join(" ");
};

// module.exports = {
//   default,
// };
