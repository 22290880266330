<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].display.label"
                        vid="display"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice settore"
                        :rules="getRules('display')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_hidden"
                        :name="beForm[rep].is_hidden.label"
                        label="Nascondi comparto"
                        v-model="form[rep].is_hidden"
                        :options="beForm[rep].is_hidden.options"
                        :rules="getRules('is_hidden')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_sundry"
                        :name="beForm[rep].is_sundry.label"
                        label="Mostra in oneri vari nel foglio quadratura"
                        v-model="form[rep].is_sundry"
                        :options="
                          beForm[rep].is_sundry.options || [
                            { value: 'N', text: 'No' },
                            { value: 'Y', text: 'Sì' },
                          ]
                        "
                        :rules="getRules('is_sundry')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Estratti Conto" header-tag="header" class="mt-2">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        :name="beForm[rep].initial_balance_date.label"
                        vid="initial_balance_date"
                        :label="beForm[rep].initial_balance_date.label"
                        v-model="form[rep].initial_balance_date"
                        placeholder="Inserisci un codice settore"
                        :rules="getRules('initial_balance_date')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].initial_balance.label"
                        vid="initial_balance"
                        :label="beForm[rep].initial_balance.label"
                        v-model="form[rep].initial_balance"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('initial_balance')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Flusso contabilità fiscale"
                header-tag="header"
                class="mt-2"
                v-if="canVerb('fattutto', 'config')"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        :name="beForm.fattutto.active.label"
                        vid="fattutto_active"
                        groupLabel=""
                        :label="beForm.fattutto.active.label"
                        v-model="form.fattutto.active"
                        :chk_val="true"
                        :unchk_val="false"
                        :disabled="beForm.fattutto.read_only.value"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm.fattutto.code.label"
                        vid="fattutto_code"
                        :label="beForm.fattutto.code.label"
                        v-model="form.fattutto.code"
                        placeholder="Inserisci un codice"
                        textcase="upper"
                        :rules="getCustomRules('fattutto_code')"
                        :disabled="beForm.fattutto.read_only.value"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm.fattutto.partitario.label"
                        vid="fattutto_partitario"
                        :label="beForm.fattutto.partitario.label"
                        v-model="form.fattutto.partitario"
                        :options="[
                          { value: 0, text: 'Compagnia' },
                          { value: 1, text: 'Produttore' },
                          { value: 2, text: 'Codice' },
                          { value: 3, text: 'Nessuno' },
                        ]"
                        :rules="getCustomRules('fattutto_partitario')"
                        :disabled="beForm.fattutto.read_only.value"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.fattutto.partitario === 2"
                    >
                      <base-input
                        :name="beForm.fattutto.partitario_code.label"
                        vid="fattutto_partitario_code"
                        :label="beForm.fattutto.partitario_code.label"
                        v-model="form.fattutto.partitario_code"
                        placeholder="Inserisci un codice di massimo 5 caratteri"
                        maxlength="5"
                        textcase="upper"
                        :disabled="beForm.fattutto.read_only.value"
                      /></div
                  ></b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        :name="beForm.fattutto.negative_enabled.label"
                        vid="negative_enabled"
                        groupLabel=""
                        :label="beForm.fattutto.negative_enabled.label"
                        v-model="form.fattutto.negative_enabled"
                        :chk_val="true"
                        :unchk_val="false"
                        :disabled="beForm.fattutto.read_only.value"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.fattutto.negative_enabled"
                    >
                      <base-input
                        :name="beForm.fattutto.negative_code.label"
                        vid="negative_code"
                        :label="beForm.fattutto.negative_code.label"
                        v-model="form.fattutto.negative_code"
                        placeholder="Inserisci un codice di massimo 15 caratteri"
                        textcase="upper"
                        maxlength="15"
                        :rules="getCustomRules('negative_code')"
                        :disabled="beForm.fattutto.read_only.value"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { setFormValues } from "@/utils/forms";
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [FormMixin, ShowMixin, ShortcutMixin],
  data() {
    return {
      repository: "sector",
      id: this.$route.params.id,
      details: {},
      // #1023 serve a inglobare tutti i sector che hanno il nuovoCampo a Y, rimuovendo gli stessi dalle tabelle
      form: {
        fattutto: {
          code: "",
          active: false,
          partitario: null,
          partitario_code: null,
          negative_enabled: false,
          negative_code: "",
          read_only: false,
        },
        sector: {
          display: null,
          title: null,
          is_abstract: "N",
          is_hidden: null,
          is_system: "N",
          is_agency: "N",
          is_sundry: "N",
          initial_balance_date: null,
          initial_balance: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    BaseCurrency,
  },
  methods: {
    canVerb,
    getCustomRules(field) {
      let rules = {};
      if (field === "fattutto_code") {
        if (this.form.fattutto.active) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "fattutto_partitario") {
        if (this.form.fattutto.active) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "negative_code") {
        if (this.form.fattutto.negative_enabled) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          /* SAVE HERE FATTUTTO CONFIG with a new API call */
          const Repo = RepositoryFactory.get("fattutto");
          const payload = {
            configurable_id: this.id,
            configurable_type: "sector",
            code: this.form.fattutto.code,
            ledger_type: this.form.fattutto.partitario,
            ledger_code: this.form.fattutto.partitario_code,
            // note: "",
            active: this.form.fattutto.active,
            negative_code: this.form.fattutto.negative_code,
          };
          (this?.details?.id
            ? Repo.update_configuration(this.details.id, payload)
            : Repo.store_configuration(payload)
          )
            .then(() => {
              this.$store.dispatch("auth/sectors");
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Comparto di compagnia modificato`,
              });
              this.shortcut(
                "module.PROF.COMP",
                null,
                "#CompanySectors",
                "filterCompanySectors"
              );
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
  created() {
    this.isLoading = true;
    const Repo = RepositoryFactory.get("fattutto");
    console.log("Repo", Repo);
    this.fetchShowForm(this.repository, this.id).then((response) => {
      console.log("response", response);
      const data = response.data.data?.fattutto_configuration?.[0] ?? {};
      // response.data.data.fattutto_configuration[0]
      this.details = data;
      this.fetchEditForm(this.repository, this.id).then(() => {
        this.beForm.fattutto = {};
        this.beForm["fattutto"].active = {
          label: `Comparto attivo all'esportazione per contabilità fiscale`,
          type: "select",
          value: this.form.fattutto.active,
        };
        this.beForm["fattutto"].code = {
          label: `Codice Comparto per flusso esportazione contabilità fiscale`,
          type: "text",
          value: this.form.fattutto.code,
        };
        this.beForm["fattutto"].partitario = {
          label: `Partitario`,
          type: "select",
          value: this.form.fattutto.partitario,
        };
        this.beForm["fattutto"].partitario_code = {
          label: `Codice Partitario`,
          type: "text",
          value: this.form.fattutto.partitario_code,
        };
        this.beForm["fattutto"].negative_enabled = {
          label: `Codice per flusso negativo`,
          type: "select",
          value: this.form.fattutto.negative_enabled,
        };
        this.beForm["fattutto"].negative_code = {
          label: `Codice Comparto per flusso negativo`,
          type: "text",
          value: this.form.fattutto.negative_code,
        };
        this.beForm["fattutto"].read_only = {
          label: "read_only",
          type: "select",
          value: this.form.fattutto.read_only,
        };
        if (Object.entries(this.details).length) {
          this.beForm["fattutto"].active.value = Boolean(this.details.active);
          this.beForm["fattutto"].code.value = this.details.code;
          this.beForm["fattutto"].partitario.value = this.details.ledger_type;
          this.beForm["fattutto"].partitario_code.value =
            this.details.ledger_code;
          if (this.details.negative_code) {
            this.beForm["fattutto"].negative_enabled.value = true;
            this.beForm["fattutto"].negative_code.value =
              this.details.negative_code;
          }
          if (this.details.read_only) {
            this.beForm["fattutto"].read_only.value = this.details.read_only;
          }
        }
        this.form["fattutto"] = setFormValues(
          [
            "active",
            "code",
            "partitario",
            "partitario_code",
            "negative_enabled",
            "negative_code",
            "read_only",
          ],
          this.beForm["fattutto"]
        );
        this.isLoading = false;
      });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
